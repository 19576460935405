import { Button, Dialog, DialogTitle, DialogActions, IconButton, Typography } from "@mui/material";
import { updateTicketState } from "../../../../ReduxTLK/features/SAV/TicketSlice";
import { useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import Snackbars from "../../../../GlobalComponents/Alerts/Snackbars";

import H4 from "../../../../GlobalComponents/TypoGraphy/H4";
import { Colors } from "../../../../GlobalComponents/Utility/Colors";

import GridItem from "../../../../GlobalComponents/Grid/GridItem";
import GridContainer from "../../../../GlobalComponents/Grid/GridContainer";

import SmallText2 from "../../../../GlobalComponents/TypoGraphy/SmallText2";

import { Form, Input, Select } from 'antd';
import CloseIcon from '@mui/icons-material/Close';

import { fetchCauses } from "../../../../ReduxTLK/features/Causes/CauseSlice";


export default function MettreEnAttenteTicketModal(props) {
    const openTicket = props.openTicket
    const [form] = Form.useForm();
    const { TextArea } = Input;
    const { Option } = Select;
    const status = 1
    const dispatch = useDispatch()
    const handleClose = () => {
        props.setOpenModal(false)
        form.resetFields();
    }
    const [openErrorBox, setOpenErrorBox] = useState(false);
    const [openSuccessBox, setOpenSuccessBox] = useState(false);
 
    const [, setSeverity] = useState("success");
    const [message, setMessage] = useState("")
    const [cause, setCause] = useState();
    const [, setNote] = useState();
    const [errorCause, setErrorCause] = useState(false)
    const [array_causes, setArray_causes] = useState([])
    const connectionId = localStorage.getItem('connectionId')
    useEffect(() => {
         dispatch(fetchCauses()).then((response) => {
          
            setArray_causes(response.payload.data?.data.result)
         })
    }, [])
    const handleExecute = async (values) => {

        if (cause === null || cause === undefined) {
            setErrorCause(true)
        }
        else {
            dispatch(updateTicketState({ Id: openTicket, status: status, cause : values.cause, note : values.note, connectionId : connectionId })).then((res) => {
                handleClose();
                if (res?.payload?.status === 200) {
                    setSeverity('success');
                    setMessage('Le Ticket a été mis en attente ');
                    setOpenSuccessBox(true);
                    setCause('');
                    setNote();
                    props.fetchData();
                    props.fetchMessages()
                }
                else {
                    setSeverity('error');
                    setCause('');
                    setNote();
                    setMessage('Un problème est survenu');
                    setOpenErrorBox(true);

                }

                props.fetchMessages();
            });
            form.resetFields();
        }

    }
    
    const onValuesChange = (changedValues, allValues) => {

        const [key, value] = Object.entries(changedValues)[0];
        
        if (key === 'cause') {
            setCause(value);

        }
    }
    const tagRender = (props) => {
        const { label } = props;
        setCause(props.value);
        setErrorCause(false);
        return (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: 3,
                    margin: 2,



                }}
            >
                <span style={{ marginRight: 8, }}>{label}</span>
            </div>
        );
    };


    return (
        <>
            <Dialog open={props.openModal} fullWidth maxWidth='sm'  sx={{
          '& .MuiPaper-root': {
           
            borderRadius: '12px', 
          },
      }}>

                <DialogTitle>
                    <GridContainer>
                        <GridItem sx={11.5} sm={11.5} md={11.5} xl={11.5} xxl={11.5}>
                            <H4 title={"Êtes vous sûre de vouloir mettre en attente ce ticket ?"}></H4>

                        </GridItem>
                        <GridItem sx={0.010} sm={0.010} md={0.010} xl={0.010} xxl={0.010}>
                            <IconButton sx={{ marginRight: 0, color: `${Colors.primary}` }} onClick={handleClose}>
                                <CloseIcon></CloseIcon>
                            </IconButton>
                        </GridItem>
                    </GridContainer>
                </DialogTitle>

                <Form
                    //{...layout}
                    form={form}
                    name="control-hooks-suspendTicket"
                    onFinish={handleExecute}
                    onValuesChange={onValuesChange}
                    ref={props.formRef}
                >
                    <GridContainer
                        display='flex'
                        justifyContent='center'
                        alignItems='flex-start'
                        direction='row'
                        gap='var(--spacing-3xs, 8px)'
                        flex='1 0 0'

                    >



                        <GridItem sx={12} sm={12} md={12} xl={12} xxl={12} padding='32px 32px 44px 32px'>
                            <Typography sx={{
                                color: 'var(--black, #000)',
                                fontFamily: 'Inter',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: 'normal',
                                marginBottom: '10px',
                            }}>
                                Causes *
                            </Typography>
                            <Form.Item
                                name="cause"
                                //  onChange={handleChange}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ce champ est obligatoire !',
                                    },
                                ]}
                            >
                                <Select

                                    showSearch
                                    tagRender={tagRender}
                                    maxCount={1}
                                    dropdownStyle={{ zIndex: 9999 }}

                                    style={{
                                        width: '100%',
                                        border: '1px ',
                                        boxShadow: '0px 1px 2px 0px #1018280D',
                                        //   color: Colors.grey3,
                                        borderRadius: '8px',
                                        gap: 'spacing-3xs',
                                        height: 'Hug (44px)',
                                    }}
                                >
                                    {array_causes.map((cause) => (
                                        <Option key={cause.valeur} value={cause.valeur}>
                                            <div style={{
                                                marginRight: 8, 
                                             

                                                borderRadius: 'var(--4, 4px)',
                                                width: '270px',
                                                height: '26px',
                                                border: '1px solid var(--Light-mode-Primary200, #D9D8FF)',
                                                background: 'var(--Light-mode-Primary100, #F0F0FF)'
                                            }}>{cause.text}</div>
                                        </Option>
                                    ))}



                                </Select>
                            </Form.Item>
                            {errorCause ? <SmallText2 title={'vous devez selectionner la cause!'} color="red"></SmallText2> : null}
                            <Typography sx={{
                                color: 'var(--black, #000)',
                                fontFamily: 'Inter',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: 'normal',
                                marginBottom: '10px',
                            }}>
                                Descriptions
                            </Typography>
                            <Form.Item
                                name="note"

                                rules={[
                                    {
                                        required: false,

                                    },
                                ]}
                            >
                                <TextArea
                                    rows={9}
                                    maxLength='350'
                                     count={{
                                        show: true,
                                        max: 350,
                                      }} 
                                    autoSize={{ minRows: 9, maxRows: 9 }}
                                    placeholder="Decrivez..."
                                />
                            </Form.Item>

                        </GridItem>
                    </GridContainer>


                    <DialogActions>
                        <Form.Item
                            style={{ marginTop: '-25px' }}

                        >

                            <Button
                                sx={{
                                    textTransform: "none",
                                    border: '2px solid #2A66C6',
                                    color: '#2A66C6',
                                    borderColor: '#2A66C6',
                                    borderRadius: '18px',
                                    width: '93px',
                                    height: '40px',
                                }}
                                onClick={handleClose} variant="outlined" shape="rounded">Annuler</Button>


                            <Button type="submit" variant="contained" shape="rounded"
                                sx={{

                                    textTransform: "none",

                                    '&.MuiButtonBase-root': {
                                        color: '#FFFFFF',
                                        backgroundColor: '#2A66C6',
                                        borderRadius: '32px',

                                        height: '40px',
                                        width: '160px',
                                        minWidth: '100px',
                                        marginLeft: 2,
                                        marginRight: 3,
                                        boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)'

                                    }
                                }}

                            >Sauvegarder</Button>

                        </Form.Item>



                    </DialogActions>
                </Form>
            </Dialog>
            <Snackbars severity={'success'} message={message} open={openSuccessBox} setOpen={setOpenSuccessBox} autohideduration={1000} />
            <Snackbars severity={'error'} message={message} open={openErrorBox} setOpen={setOpenErrorBox} autohideduration={1000} />

        </>
    )
}