import React, { useContext, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Logo from '../assets/images/Logo.svg';
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import "./style.css"
import { loggedUser } from '../ReduxTLK/features/User/LoginSlice';
import { Form, Input } from 'antd';
import { NavContext } from '../App';

import ErrorBox from './Alerts/ErrorBox';

import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { Dialog, DialogTitle, DialogActions, DialogContent, Typography, IconButton, Popover, Drawer } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { Colors } from "../GlobalComponents/Utility/Colors";
import H5 from "../GlobalComponents/TypoGraphy/H5";
import H4 from "../GlobalComponents/TypoGraphy/H4";
import signalRService from '../GlobalComponents/Utility/SignalRConnection';
import GridItem from "../GlobalComponents/Grid/GridItem";
import GridContainer from "../GlobalComponents/Grid/GridContainer"
import EastIcon from '@mui/icons-material/East';
import SAVIcon from './IconComponents/SAVIcon';
import ListAllTickets from '../Pages/SAV/TicketList/ListAllTickets';
import { clearTabs, setLastActiveTab } from '../ReduxTLK/features/Tabs/TabsSlice';
import CheckedIcon from './IconComponents/CheckedIcon';

import DotGreyIcon from './IconComponents/DotGreyIcon';
import './emailValidation.css';
import { resetAllEditDraftTicket, resetNewTicketDraft } from '../ReduxTLK/features/SAV/NewTicketDraftSlice';
import { Persistor } from '..';

const defaultTheme = createTheme();
/**
 * 
 * @returns  sign-in form that uses
Material-UI components for the user interface. */


const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 22,
  },
};




export default function SignIn() {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [timezone, setTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone)
  localStorage.removeItem('TZ');
  localStorage.setItem('TZ', timezone);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [email, setEmail] = useState('');
 
  const [validations, setValidations] = useState({
    dotMissing: true,
    atSymbolMissing: true,
    misplacedSymbols: true,
    malformedEmail: true,
    tooShort: true,
    isEmpty: true,
  });

  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [openForceLogin, setOpenForceLogin] = useState(false);
  const [openValidator, setOpenValidator] = useState(false)
  const [severity, setSeverity] = useState("error");
  const [message, setMessage] = useState("")
  const context = useContext(NavContext)
  const [password, setPassword] = useState()
  const [force, setForce] = useState(true)
  useEffect(() => {
    dispatch(resetNewTicketDraft());
    dispatch(resetAllEditDraftTicket());
    Persistor.persist();
  }, [])
  const handleClose = () => {
    setOpen(false)
    setOpenForceLogin(false)

  }
  useEffect(() => {
    const emailValue = form.getFieldValue('email');
    
    validateEmail(emailValue);
  }, [form]);
  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    
    setEmail(newEmail);
    validateEmail(newEmail);
  };
  const validateEmail = (email) => {
    if(email?.length > 0) {
        const dotMissing = email.includes('.');
        const atSymbolMissing = email.includes('@');
        const misplacedSymbolsTest = () => {
            if (dotMissing === true && atSymbolMissing === true) {
                return email.lastIndexOf('@') > email.lastIndexOf('.') ? false : true;
            } else {
                return false;
            }
        };

        const isEmpty = email.length === 0 ? false : true;

        const malformedEmail = email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i)
      

        setValidations({
            dotMissing,
            atSymbolMissing,
            misplacedSymbols: misplacedSymbolsTest(),
            malformedEmail,
            isEmpty
        });

        if (malformedEmail) {
            return;
        }

    } else {
        setValidations({
            dotMissing: false,
            atSymbolMissing: false,
            misplacedSymbols: false,
            malformedEmail: false,
            tooShort: false,
            isEmpty: false
        });
    }
};

const validateEmailForm = (rule, value) => {
    const email = value; 

    const dotMissing = !email.includes('.');
    const atSymbolMissing = !email.includes('@');
    const misplacedSymbols = email.lastIndexOf('@') > email.lastIndexOf('.');
    const malformedEmail = !email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i)
   

    const isEmpty = email.length === 0 ? true : false;

    if (malformedEmail || dotMissing || atSymbolMissing || misplacedSymbols || isEmpty) {
        return Promise.reject();
    }

    return Promise.resolve();
};


 
  const onFinish = async (values) => {
    setIsLoading(true);
    setLoadingMessage('Connexion en cours'); // Display loading message

    // Simulate a delayed request (replace with your actual login logic)



    setEmail(values.email)
    setPassword(values.password)
    setTimeout(() => {

      localStorage.removeItem('Token')
      localStorage.removeItem('userId')
      localStorage.removeItem('userlastName')
      //  const notifToken = localStorage.getItem('notifToken');
      dispatch(clearTabs())
      dispatch(loggedUser({
        ...values,
        //  DeviceNotificationId: notifToken 
      }))
        .then((res) => {

          if (res?.payload?.data?.status === 'Success') {
            context.connectToSignalR();
            signalRService.onJoinRoom('SAV');
            context.setNotifcationHub('SAV')
            const Token = 'Bearer ' + res.payload?.data?.data?.result?.token
            localStorage.setItem('TokenStored', Token);
            localStorage.setItem('Token', res.payload?.data?.data?.result?.token);
            localStorage.setItem('userId', res?.payload?.data?.data?.result?.user?.id);
            localStorage.setItem('userlastName', res?.payload?.data?.data?.result?.user?.lastName);
            //context.joinRoom();
            setIsLoading(false);
            setLoadingMessage('');
            context.setLogged(true)
            context.setTabs([
              {
                id: 3,
                label: "Tous les tickets",
                component: <ListAllTickets />,
                closeable: false,
                icon: <SAVIcon />,
                collapse: false,
                invisible: false,
              }
            ])
            context.setActiveTab(3)
            dispatch(setLastActiveTab(3));
           
            navigate('/dashboard')
          }
          else {
            setIsLoading(false);
            setLoadingMessage('');
            if (res.payload?.data?.failureResponse?.codeFI?.code === 802) {
              setSeverity('error');
              setMessage("Votre mail ou mot de passe est incorrect");
              setOpen(true);
            }
            if (res.payload?.data?.failureResponse?.codeFI?.code === 807) {


              setOpenForceLogin(true);



            }



          }
        });

    }, 2000)

  };
  const handleForceLogin = () => {
    setTimeout(() => {

      localStorage.removeItem('Token')
      localStorage.removeItem('userId')
      localStorage.removeItem('userlastName')
      const notifToken = localStorage.getItem('notifToken');

      dispatch(loggedUser({ email: email, password: password, forceLogin: force, DeviceNotificationId: notifToken }))
        .then((res) => {

          if (res?.payload?.data?.status === 'Success') {


            
            context.connectToSignalR();
            signalRService.onJoinRoom('SAV');
            context.setNotifcationHub('SAV')
            const Token = 'Bearer ' + res.payload?.data?.data?.result?.token
            localStorage.setItem('TokenStored', Token);
           
            localStorage.setItem('Token', res.payload?.data?.data?.result?.token);
            localStorage.setItem('userId', res?.payload?.data?.data?.result?.user?.id);
            localStorage.setItem('userlastName', res?.payload?.data?.data?.result?.user?.lastName);
          
            context.setLogged(true)
            dispatch(clearTabs())
            context.setTabs([

              //   {
              //   id: 1,
              //   label: null,
              //   component: <DefaultTab />,
              //   closeable: false,
              //   icon: <HomeIcon />,
              //   collapse: false,
              //   invisible: false,
              // },
              // {
              //   id: 2,
              //   label: "Ajout ticket",
              //   component: <New />,
              //   closeable: false,
              //   icon: <SAVIcon />,
              //   collapse: false,
              //   invisible: false,
              // },
              {
                id: 3,
                label: "Tous les tickets",
                component: <ListAllTickets />,
                closeable: false,
                icon: <SAVIcon />,
                collapse: false,
                invisible: false,
              }
            ])
            context.setActiveTab(3)
            dispatch(setLastActiveTab(3));
            // Redirect();
            navigate('/dashboard')

            // loginSuccess(res?.payload?.data?.data?.result);
          }
          else {

            if (res.payload?.data?.failureResponse?.codeFI?.code === 802) {
              setSeverity('error');
              setMessage("Votre mail ou mot de passe est incorrect");
              setOpen(true);
            }
            if (res.payload?.data?.failureResponse?.codeFI?.code === 807) {


              setOpenForceLogin(true);



            }



          }
        });

    }, 2000)

    handleClose();

  }

  const LoaderComponent = () => {
    return (
      <><center>
        <Typography sx={{
          color: `${Colors.primary}`,
          marginTop: 10,
          textAlign: 'center',
          fontFamily: "DM Sans",
          fontSize: '18px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: '62px', /* 238.462% */
          letterSpacing: '-1.5px',
        }} >Connexion en cours</Typography>

        <Spin indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />} />
      </center>

      </>)
  }
  const validate = () => {
    setOpenValidator(!openValidator); 
  }
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main"
        maxWidth="xxl" sx={{
          display: "flex",
          height: "100%",
          width: "100%",
          padding: "80px 100px 427px 100px",
          justifyContent: "center",
          alignItems: "center",
          background: "#F4F7FE"
        }}>

        <Box
          sx={{
            marginTop: '1%',
            display: 'flex',
            flexDirection: 'Column',
            alignItems: 'center',
            height: "100%",
            width: "50%",
          }}
        >
          {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}> */}

          <Container component="section" maxWidth="xs" margintop="big" sx={{ mt: '2%' }}>
            <img src={Logo} alt='Logo' width="100%" />
            {/* </Avatar> */}
            <br></br>
            <br></br>
            {/* <Typography component="h1" variant="h5" align='center'>
            Sign in
          </Typography> */}
            {/* <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}> */}
            <Typography sx={{
              color: '#384356',

              textAlign: 'center',
              fontFamily: "DM Sans",
              fontSize: '26px',
              fontStyle: 'normal',
              fontWeight: 700,
              lineHeight: '62px', /* 238.462% */
              letterSpacing: '-1.5px',
            }} >Connectez vous</Typography>
            <Typography sx={{
              color: '#384356',

              textAlign: 'center',
              fontFamily: 'DM Sans',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '30px',
              marginBottom: '60px'
              /* 238.462% */
              // letterSpacing: '-2px',
            }} >Accédez à votre compte en un instant</Typography>

            <Form
              {...layout}
              form={form}
              name="control-hooks"
              onFinish={onFinish}
            >
              <Form.Item
                name="email"
                type="email"
                rules={[
                  {
                    required: true,
                    message: 'Ce champ est obligatoire !',
                  },

                  {
                    validator: validateEmailForm,
                  },

                ]}

              >
                <Input onChange={handleEmailChange} onFocus={validate} onBlur={validate} placeholder="Email" style={{
                  height: '48px',
                  mt: '27',
                  width: '398px',
                  backgroundColor: 'white'
                }} />

              </Form.Item>

              <Drawer
                open={openValidator}
                variant='persistent'
                hideBackdrop
                anchor="left"
                transformOrigin={{
                  vertical: 'right',
                  horizontal: 'right',
                }}
                PaperProps={{
                  sx: {
                    height: '25%',
                    width: '15%',
                    position: 'fixed',
                    marginLeft: "450px",
                    marginTop: "400px",
                    marginRight: 2,
                    overflowY: 'auto',
                    border: '1px solid #dad8d8',
                    borderRadius: '12px',
                  },
                }}
              >
                <div className="validation-messages">
                  {Object.entries(validations).map(([condition, isValid]) => (

                    <div key={condition} className={`validation-message ${isValid ? 'valid' : ''}`}>
                      {isValid ? <CheckedIcon /> : <DotGreyIcon />}
                      {condition === 'dotMissing' && <>{isValid ? 'Le "." est vérifié.' : 'Le "." est manquant.'}</>}
                      {condition === 'atSymbolMissing' && <>{isValid ? 'L\' "@" vérifié.' :  'L\' "@" est manquant.'}</>}
                      {condition === 'misplacedSymbols' && <>{isValid ? 'L\' "@" et le "." sont bien placés.' : 'L\' "@" et le "." sont mal placés.'}</>}
                      {condition === 'malformedEmail' && <>{isValid ? 'Email bien formé' : 'Email mal formé.'}</>}
                      {condition === 'tooShort' && <>{isValid ? 'Le longueur du mail est acceptable' : 'Email trop court.'}</>}
                      {condition === 'isEmpty' && <>{isValid ? 'Email rempli' : 'Email vide.'}</>}
                    </div>
                  ))}
                </div>
              </Drawer>
              {/* <EmailValidation
        email={email}
        validations={validations}
        onEmailChange={handleEmailChange}
      /> */}
              <Form.Item
                name="password"
                // label="Name"
                //readOnly
                rules={[
                  {
                    required: true,
                    message: 'Ce champ est obligatoire !',
                  },
                ]}

              >
                <Input
                  type='password'
                  placeholder="Password"
                  style={{
                    height: 48,
                    mt: 27,
                    width: '398px',
                    backgroundColor: 'white'

                  }}
                />

              </Form.Item>
              {isLoading ? <LoaderComponent /> : (<>
                <Form.Item >
                  <Button
                    type="submit"
                    color="primary"
                    variant='contained'
                    //  loading={LoaderComponent} // Display loading spinner when isLoading is true
                    //  disabled={isLoading} // Disable button while loading
                    //width="200px"
                    sx={{
                      '&.MuiButtonBase-root': {
                        // color: '#2A66C6',
                        // borderColor: '#2A66C6',
                        borderRadius: '12px',
                        width: '398px',
                        height: '40px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '20%'
                        // ":disabled": {
                        //   backgroundColor: ' #F3F4F6',
                        //   color: '#9CA3AF',
                        //   borderColor: '#F3F4F6',

                        // }
                      }
                    }}

                  >
                    <Typography sx={{
                      color: '#FFF',

                      textAlign: "center",
                      fontFamily: "DM Sans",
                      fontSize: "18px",
                      // fontStyle: 'normal',
                      // fontWeight: "700",
                      lineHeight: "22.512px",
                      // weight : "24px",
                      // height : "20px",
                      textTransform: "none",
                    }} >

                      Se connecter
                    </Typography>   <EastIcon sx={{ ml: 5 }}></EastIcon>



                  </Button>
                </Form.Item></>)}
            </Form>


            {/* </Box> */}
          </Container>
        </Box>
        <ErrorBox severity={'error'} message={message} open={open} setOpen={setOpen} autoHideDuration={1000} />
        {/* <ForceLogin  open={openForceLogin} setOpen={setOpenForceLogin} onFinish={onFinish}/> */}
        <Dialog open={openForceLogin} fullWidth
          maxWidth='sm'
          sx={{
            '& .MuiPaper-root': {
             
              borderRadius: '12px', 
            },
        }}
           >
            
            <br></br>
         
            <div style={{
              display : 'flex',
              direction :'column'
            }}>
              <span style={{color: 'var(--base-900-light, #1A1A1A)',
fontFamily: 'DM Sans',
fontSize: '16px',
fontStyle: 'normal',
fontWeight: 600,
lineHeight: '34px',
marginLeft: '26px'
}}>Se connecter </span>

              <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 2,
              color: `${Colors.primary}`,
            }}
          >
            <CloseIcon fontSize='small' />
          </IconButton>
            </div>
           
          
          <DialogContent sx={{ marginLeft: '10px', marginTop: '10px' }}>
            <span style={{
              color: 'var(--palette-background-light-700-background-light-700-alpha-100, #525252)',
              fontFamily: "DM Sans",
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '22px'
            }}>Ce compte est connecté sur un autre appareil</span>
            <br></br>
            <span  style={{
              color: 'var(--palette-background-light-700-background-light-700-alpha-100, #525252)',
              fontFamily: "DM Sans",
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '22px',
              //width : '900px'
            }}>souhaitez-vous vous déconnecter de l'autre appareil ?</span>
            
          </DialogContent>

          <DialogActions>
            <GridContainer
              display='flex'
              justifyContent='right'
              //alignItems='flex-end'
              direction='row'

            >
              <GridItem sx={2} sm={2} md={2} xl={2} xxl={2} paddingLeft='0px'>
                <Button sx={{
                  textTransform: "none",
                  border: '2px solid #2A66C6',
                  color: '#2A66C6',
                  borderColor: '#2A66C6',
                  borderRadius: '18px',
                  width: '93px',
                  height: '40px',
                }}
                  onClick={handleClose} variant="outlined" shape="rounded">Non</Button>
              </GridItem>
              <GridItem sx={2} sm={2} md={2} xl={2} xxl={2} paddingRight='2px'>
                <Button sx={{
                  // size : 'large', 
                  textTransform: "none",
                  borderRadius: 32,
                  '&.MuiButtonBase-root': {
                    color: '#FFFFFF',
                    backgroundColor: '#2A66C6',
                    borderRadius: '18px',
                    // width: '99px',
                    height: '40px',
                    width: '100px',
                    minWidth: '100px',
                    
                  }
                }}
                  onClick={handleForceLogin} variant="contained" shape="rounded"
                //color={Colors.primary}
                >Oui</Button>
              </GridItem>
            </GridContainer>
          </DialogActions>
        </Dialog>
      </Container >
    </ThemeProvider >

  );
}
