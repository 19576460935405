import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Routes, Route, } from "react-router-dom";
import Auth from './layouts/auth';
import DashboardLayout from './layouts/dashboard';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Colors } from './GlobalComponents/Utility/Colors';
import ListAllTickets from './Pages/SAV/TicketList/ListAllTickets';
import SAVIcon from './GlobalComponents/IconComponents/SAVIcon';
import signalRService from './GlobalComponents/Utility/SignalRConnection';
import BaseUrl from './assets/utils/accessToken';

import InfoBox from './GlobalComponents/Alerts/InfoBox';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getNewNotificationsNumber } from "./ReduxTLK/features/Notifications/NotificationSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { Persistor } from '.';

import { resetAllEditDraftTicket, resetNewTicketDraft } from './ReduxTLK/features/SAV/NewTicketDraftSlice';

import { addTab, removeTabById, setLastActiveTab } from './ReduxTLK/features/Tabs/TabsSlice';
import GenericDialogAddTicketSaveConfirmation from './GlobalComponents/Dialog/GenericDialogAddTicketSaveConfirmation';

import AlertBox from './GlobalComponents/Alerts/AlertBox';
import GenericDialogCancelledTicket from './GlobalComponents/Dialog/GenericDialogCancelledTicket';
import ViewSAVTicket from './Pages/SAV/TicketDetails/View/ViewSAVTicket';




export const NavContext = React.createContext();
/**
 * The main React component for the application.
 *
 * @returns {React.Component}
 */
export const instance = axios.create({
  baseURL: BaseUrl,
});
function App() {
  const [tabs, setTabs] = useState([]);
  const [maxTabIndex, setMaxTabIndex] = useState(4);
  const [activeTab, setActiveTab] = useState(3);
 
  
  const [selectedTicket, setSelectedTicket] = useState();
  const [, setNotification] = useState({ title: '', body: '' });
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
 
  const [message, setMessage] = useState("")
  const [header, setHeader] = useState("")
  const [idNotif, setIdNotif] = useState("")
  const [newNotification, setNewNotification] = useState()
  const [isConnected, setIsConnected] = useState(false);
  //const [isConnectedToNotificationHub, setIsConnectedToNotificationHub] = useState(false);
  const [isInRoom, setIsInRoom] = useState(false);
  const [roomId, setRoomId] = useState(false);
  const [receivedMessage, setReceivedMessage] = useState()

  const [, setNotifcationHub] = useState()
  const [openReloadConfirmation, setOpenReloadConfirmation] = useState(false);
  const [confirmedReload, setConfirmedReload] = useState(false)
  const [cancelledReload, setCancelledReload] = useState(false)
  const [openCancelledTicketAlert, setOpenCancelledTicketAlert] = useState(false);
  const [notifStatus, setNotifStatus] = useState();
  const isUnmounted = useRef(false);
  const navigate = useNavigate();
  const dispatch = useDispatch()


  useEffect(() => {

    setTabs([
      {
        id: 3,
        label: "Tous les tickets",
        component: <ListAllTickets />,
        closeable: false,
        icon: <SAVIcon />,
        collapse: false,
        invisible: false,
      }

    ])
    // setTabs(defaultTabs);
  }, []);
  const activeTabRef = useRef(activeTab);
  useEffect(() => {
    activeTabRef.current = activeTab;
  }, [activeTab]);

  // useEffect(() => {

  //   dispatch(setLastActiveTab(activeTab));
  // }, [activeTab])

  const connectToSignalR = async () => {

    try {
      await signalRService.startConnection(BaseUrl + "/chatHub");
      setIsConnected(true);
      //  signalRService.onJoinRoom('SAV');
    } catch (error) {
      // Check if the component is still mounted before retrying
      if (!isUnmounted.current) {
        // Retry connection after a delay
        setTimeout(connectToSignalR, 2000);
      }
    }
  };


  /**********signalR configs and join rooms */
  useEffect(() => {
    isUnmounted.current = false;
    connectToSignalR();

    return () => {
      isUnmounted.current = true;
      signalRService.connection.stop();

    };
  }, []);
  
  useEffect(() => {
    signalRService.onJoinRoom('SAV')
    signalRService.onConnectUser()
    signalRService.onReceiveNotification((receivedNotification) => {
      
      setNotifStatus(receivedNotification?.statut)
      getNotification()
      const currentActiveTab = activeTabRef.current;
      const actionNotification = receivedNotification.synchronisation?.type
      const id = receivedNotification.idTicket
      const title = '#' + id;
      const tabid_cancelled = 'SAV_Ticket_' + receivedNotification?.synchronisation?.data;
      
      const tabid = 'SAV_Ticket_' + id
      if(receivedNotification?.statut === 6)
      {
        reopenInViewTicket({id, tabid, title})
        
      }
      if (currentActiveTab !== undefined && currentActiveTab !== null && currentActiveTab !== 3 && currentActiveTab !== 2 
        && actionNotification !== 'ticket_annulee'
       ) {

        if (currentActiveTab !== tabid) {
          setHeader(receivedNotification?.titre);
          setMessage(receivedNotification?.contenu);

          setOpen(true);
          setNotification({
            title: receivedNotification?.titre,
            body: receivedNotification?.contenu,
          });

          setIdNotif(id)
        }
        else {
          if (actionNotification === 'update_statut') {

            const tabIndex = tabs.find(tab => tab.identifier === id)

            if (tabIndex !== -1 && currentActiveTab === tabid) {
              reloadTab(id)

            }
            //dispatch(fetchTicketById(id))
          }
          if (actionNotification === 'nouveau_message') {

            const tabIndex = tabs.find(tab => tab.identifier === id)

            //const tabid = 'SAV_Ticket_' + id

            if (tabIndex !== -1 && currentActiveTab === tabid) {

              // signalRService.onJoinRoom(id)
              setIsInRoom(true)
              setRoomId(id)

              signalRService.onJoinRoom(id);

              signalRService.onReceiveMessage((receivedMessage) => {


                setReceivedMessage(receivedMessage)
              });

            }
            //dispatch(fetchTicketById(id))
          }


          if (actionNotification === 'ajout_attachement') {


            const tabIndex = tabs.find(tab => tab.identifier === id)
            //const tabid = 'SAV_Ticket_' + id

            if (tabIndex !== -1 && currentActiveTab === tabid) {

              reloadTabAttachement(id)

            }
            //dispatch(fetchTicketById(id))
          }
        }


      }
      else  {
        /********************* */
        if (currentActiveTab !== undefined
          && currentActiveTab !== null && currentActiveTab
          !== 3 && currentActiveTab !== 2
          && actionNotification === 'ticket_annulee') {
          
          if (currentActiveTab === tabid_cancelled) {
            setMessage(receivedNotification?.contenu);
            setOpenCancelledTicketAlert(true);
          
          }
          else {
            setMessage(receivedNotification?.contenu);
            setOpenAlert(true);
            closeTab(tabid_cancelled);
          }
        }
        /********************** */
        if (currentActiveTab === 3 && actionNotification === 'ticket_annulee') {
          setMessage(receivedNotification?.contenu);
          setOpenAlert(true);
          closeTab(tabid_cancelled);
          reloadTab('allTickets')
        }
        if (currentActiveTab === 3 && actionNotification !== 'ticket_annulee') {
          
          setHeader(receivedNotification?.titre);
          setMessage(receivedNotification?.contenu);

          setOpen(true);
          setNotification({
            title: receivedNotification?.titre,
            body: receivedNotification?.contenu,
          });
          setIdNotif(id)
          reloadTab('allTickets')
        }



        if (currentActiveTab === 2 && actionNotification === 'ticket_annulee') {
           
          setMessage(receivedNotification?.contenu);

          setOpenAlert(true);
          
          closeTab(tabid_cancelled)

        }

        if (currentActiveTab === 2 && actionNotification !== 'ticket_annulee') {


          setHeader(receivedNotification?.titre);
          setMessage(receivedNotification?.contenu);

          setOpen(true);
          setNotification({
            title: receivedNotification?.titre,
            body: receivedNotification?.contenu,
          });

          setIdNotif(id)


        }

      }
    },
    );
  }, [activeTabRef, tabs]);
  /**
   *default theme :  disable scroll on main container and setting default background color to blue25
   */
  const closeTab = (id) => {
    
    const tabToDeleteIndex = tabs.findIndex(
      tab => tab.id === id
    );
    
    if(tabToDeleteIndex !== -1) {
    const updatedTabs = tabs.filter((tab, index) => {
      return index !== tabToDeleteIndex;
    });

    setTabs(updatedTabs);
    dispatch(removeTabById(id))

    Persistor.persist()
  }
  }  
  const reopenInViewTicket = ({id, tabid, title }) => {
    const updatedTabs = tabs.filter((tab) => {
               
      return tab.identifier !== id;
    });

    dispatch(removeTabById(tabid))
  
    setTabs([...updatedTabs, {
      id: tabid,
      label: title,
      identifier: id,
      component: <ViewSAVTicket id={id} />,
      closeable: true,
      icon: <SAVIcon />,
    }]);
    dispatch(addTab({
      module: 'SAV',
      action: 'View',
      identifier: id,
      id: tabid,
      label: title,
    }));
    Persistor.persist();

  }

  const defaultTheme = createTheme({

    breakpoints: {
      values: {
        sn : 645,
        xs: 400,
        sm: 600,
        cx: 691,
        md: 900,
        lg: 1280,
        xl: 1440,
        xxl: 1920
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            overflow: 'hidden',


          }
        }
      }
    },
    palette: {
      background: {
        default: Colors.blue25
      }
    },



  });
  /***************** */


  /************context values******* */

  /*****************reload browser purge persistor */

  const dirtyNewTicketDraft = useSelector((state) => {

    return state.draftNewTicket.dirty;
  });
  const editedTicketDraft = useSelector((state) => state.draftNewTicket.editedTicketDraft);
  useEffect(() => {
    //  callback logic depends on dirtyNewTicketDraft
    // It will run every time dirtyNewTicketDraft changes
    
  }, [dirtyNewTicketDraft, editedTicketDraft]);

  window.addEventListener('beforeunload', handleBeforeUnload)

  // /********** */
  function handleBeforeUnload(event) {
    if (dirtyNewTicketDraft) {

      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
      event.returnValue = false;

      // Ask the user for confirmation

      const confirmation = window.confirm('');

      // If the user confirms, refresh the page
      if (confirmation === true) {
        setConfirmedReload(true)
        signalRService.onDisconnectUser();

        if (activeTabRef.current === 3) {
          dispatch(setLastActiveTab(3));
          Persistor.persist();
        } else if (activeTabRef.current === 2) {
          dispatch(setLastActiveTab(2));
          Persistor.persist();
        } else {
          dispatch(setLastActiveTab(activeTabRef.current));
          Persistor.persist();
        }
        dispatch(resetNewTicketDraft());
        dispatch(resetAllEditDraftTicket());
        Persistor.persist();
        window.location.reload();


      }

    }
    if (editedTicketDraft?.length > 0) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
      event.returnValue = false;

      // Ask the user for confirmation

      const confirmation = window.confirm('');

      // If the user confirms, refresh the page
      if (confirmation === true) {
        setConfirmedReload(true)
        signalRService.onDisconnectUser();

        if (activeTabRef.current === 3) {
          dispatch(setLastActiveTab(3));
          Persistor.persist();
        } else if (activeTabRef.current === 2) {
          dispatch(setLastActiveTab(2));
          Persistor.persist();
        } else {
          dispatch(setLastActiveTab(activeTabRef.current));
          Persistor.persist();
        }
        dispatch(resetNewTicketDraft());
        dispatch(resetAllEditDraftTicket());
        Persistor.persist();
        window.location.reload();


      }
    }

    else {

      signalRService.onDisconnectUser();
      dispatch(resetNewTicketDraft());
      dispatch(resetAllEditDraftTicket());
      if (activeTabRef.current === 3) {
        dispatch(setLastActiveTab(3));
        Persistor.persist();
      } else if (activeTabRef.current === 2) {
        dispatch(setLastActiveTab(2));
        Persistor.persist();
      } else {
        dispatch(setLastActiveTab(activeTabRef.current));
        Persistor.persist();
      }
    }
  }
  /*************** reload handling*/
  window.addEventListener('load', function (event) {
    // Your logic for handling the load event
    dispatch(resetNewTicketDraft());
    dispatch(resetAllEditDraftTicket());
    setConfirmedReload(true)
    Persistor.persist();

  });
  /************logout handling  */


  axios.interceptors.response.use(
    (response) => response,
    function (error) {
      //const originalRequest = error.config

      if (
        error?.response?.status === 401

        //&&
        // originalRequest.url === 'http://127.0.0.1:3000/v1/auth/token'
      ) {
        setLogged(false);
        localStorage.removeItem('TokenStored');
        navigate('/');

      }
      if (error?.response?.status === 400 && error?.response?.data?.failureResponse?.codeFI?.code === 808) {
        localStorage.removeItem('TokenStored');
        setLogged(false);

        navigate('/');
      }

      return Promise.reject(error)
    }


  );

  const isAuthenticated = localStorage.getItem('TokenStored') !== null;
  const [logged, setLogged] = useState(false)
  const getNotification = () => {

    // if (logged && isAuthenticated) {
    dispatch(getNewNotificationsNumber()).then(unwrapResult).then((res) => {

      setNewNotification(res?.data?.data?.result)
    })
    // }

  }



  useEffect(() => {
    if (logged && isAuthenticated) {
      getNotification();
    }
  }, [logged])

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/');
    }

  }, [logged])

  useEffect(() => {
    if (isAuthenticated) {

      setLogged(true)
      navigate('/dashboard');
    }
    else {
      setLogged(false)
      navigate('/')
    }

  }, [logged, isAuthenticated])
  const [tabToBeReloaded, setTabToBeReloaded] = useState()

  const [tabToBeReloadedNotif, setTabToBeReloadedNotif] = useState()
  const [tabToBeReloadedAttachement, setTabToBeReloadedAttachement] = useState()
  const reloadTab = (tabId) => {

    setTabToBeReloaded(tabId)

  };

  const reloadTabAttachement = (tabId) => {

    setTabToBeReloadedAttachement(tabId)

  };

  /******************* */
  const value = {
    tabs,
    setTabs,
    maxTabIndex,
    setMaxTabIndex,
    reloadTab,
    tabToBeReloaded,
    setTabToBeReloaded,
    tabToBeReloadedNotif,
    setTabToBeReloadedNotif,
    tabToBeReloadedAttachement,
    activeTab,
    setActiveTab,
    selectedTicket,
    setSelectedTicket,
    setLogged,
    logged,
    newNotification,
    setNewNotification,
    isConnected,
    isUnmounted,
    connectToSignalR,
    isInRoom,
    roomId,
    setIsInRoom,
    setRoomId,
    receivedMessage,
    setReceivedMessage,
    setNotifcationHub,
    cancelledReload,
    setCancelledReload,
    confirmedReload,
    setConfirmedReload,
    activeTabRef
  };

  return (

    <ThemeProvider theme={defaultTheme} >

      <CssBaseline />
      <NavContext.Provider value={value} tabs={tabs} >

        {logged && isAuthenticated &&
          <>
            <InfoBox severity={'info'} idNotif={idNotif} message={message}
            notifStatus={notifStatus}
             open={open} setOpen={setOpen} autohideduration={2000} header={header} />
            <AlertBox severity={'alert'} message={message} open={openAlert} setOpen={setOpenAlert} autohideduration={2000} header={header} />
          </>
        }


        <Routes>
          {/* <Route index  element={<Navigate to="/auth/sign-in" />} /> */}
          <Route index path="/*" element={<Auth />} />


          {logged && isAuthenticated && <Route path="/dashboard/*" element={<DashboardLayout />} />}


        </Routes>
        <GenericDialogAddTicketSaveConfirmation open={openReloadConfirmation}
          setOpen={setOpenReloadConfirmation} />
        <GenericDialogCancelledTicket open={openCancelledTicketAlert} setOpen={setOpenCancelledTicketAlert} message={message} />
      </NavContext.Provider>

    </ThemeProvider>


  );
}

export default App;
