import { Button, IconButton, Pagination } from "@mui/material";
import DataGridPaginationTitle from "../../../GlobalComponents/Grid/DataGridPaginationTitle";
import GridContainer from "../../../GlobalComponents/Grid/GridContainer";
import GridItem from "../../../GlobalComponents/Grid/GridItem";
import ChevronLeftIcon from "../../../GlobalComponents/IconComponents/ChevronLeftIcon";
import { Colors } from "../../../GlobalComponents/Utility/Colors";
import ChevronRightIcon from "../../../GlobalComponents/IconComponents/ChevronRightIcon";

export default function CustomFooterComponent({ paginationModelCustom, fetchData}) {

    const handlePageChangeNext = (event) => {
  
        const data = {
          page: paginationModelCustom.page + 1,
          pageSize: paginationModelCustom.pageSize,
          nbPage: paginationModelCustom.nbPage,
          idTicket : paginationModelCustom.idTicket
     
        };
        fetchData(data);
     
      }
     
      const handlePageChangePrevious = (event) => {
       
        const data = {
          page: paginationModelCustom.page - 1,
          pageSize: paginationModelCustom.pageSize,
          nbPage: paginationModelCustom.nbPage,
          idTicket : paginationModelCustom.idTicket
     
        };
        fetchData(data);
      }
      const handleChangePage = (event) => {
       const data = {
         page: parseInt(event.target.textContent),
         pageSize: paginationModelCustom.pageSize,
         nbPage: paginationModelCustom.nbPage,
         idTicket : paginationModelCustom.idTicket
     
       };
       fetchData(data);
      }
     
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '100%', // Adjust the maximum width as needed
       
      }}>
        {paginationModelCustom && paginationModelCustom.nbPage >= 1 && (
          <>
            <div style={{ minWidth: '70%', marginLeft :'50px' }}>
              <DataGridPaginationTitle count={paginationModelCustom.nbPage} page={paginationModelCustom.page} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', minWidth: '25%', justifyContent: 'flex-end'  }}>
              <IconButton sx={{ color: Colors.primary }} disabled={paginationModelCustom.page === 1} onClick={() => handlePageChangePrevious()}>
                <ChevronLeftIcon />
              </IconButton>
              <Pagination
                color="primary"
                shape='rounded'
                page={paginationModelCustom.page}
                count={paginationModelCustom.nbPage}
                hidePrevButton
                hideNextButton
                onChange={handleChangePage}
                size='small'
              />
              <IconButton disabled={paginationModelCustom.page === paginationModelCustom.nbPage} onClick={() => handlePageChangeNext()} sx={{ color: Colors.primary }}>
                <ChevronRightIcon />
              </IconButton>
            </div>
          </>
        )}
        {paginationModelCustom && paginationModelCustom.nbPage === 0 && (
          <>
          <div style={{ minWidth: '70%', marginLeft :'50px' }}>
            <DataGridPaginationTitle count={paginationModelCustom.nbPage} page={0} />
</div>
<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', minWidth: '25%', justifyContent: 'flex-end'  }}>
            <IconButton sx={{ color: Colors.primary }}>
              <ChevronLeftIcon />
            </IconButton>
            <div style={{
              shape: 'rounded',
              width: '25px',
              height: '25px',
              backgroundColor: '#EEEEEE',
              color: 'black',
              borderRadius: '4px',
            //  marginBottom: '9px',
              display: 'flex',
              alignContent: 'center',
              alignItems: 'center'
            }}>
              <span style={{ marginLeft: '8px', fontStyle: 'DM Sans', fontWeight: 500, fontSize: '12px' }}>0</span>
            </div>
            <IconButton sx={{ color: Colors.primary }}>
              <ChevronRightIcon />
            </IconButton>
            </div>
         
          </>
        )}
      </div>
      
 
    );
  }