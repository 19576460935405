import { Button, Dialog, DialogTitle, DialogActions, DialogContent } from "@mui/material";
import { executeTicket, updateTicketState } from "../../../../ReduxTLK/features/SAV/TicketSlice";
import {useDispatch } from 'react-redux';
import React, {useState} from 'react';
import Snackbars from "../../../../GlobalComponents/Alerts/Snackbars";
import H5 from "../../../../GlobalComponents/TypoGraphy/H5";
import H4 from "../../../../GlobalComponents/TypoGraphy/H4";
import { NavContext } from '../../../../App';
export default function ExecuteTicketInsideUpdateModal(props) {
    const openTicket = props.openTicket
    const status = props.status
    const dispatch = useDispatch()
    const handleClose = () => {
        props.setOpenModal(false)
    }
    const [openErrorBox, setOpenErrorBox] = useState(false);
    const [openSuccessBox, setOpenSuccessBox] = useState(false);
    const context = React.useContext(NavContext)
    const [index, setIndex] = useState(context.maxTabIndex)
    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState("success");
    const [message, setMessage] = useState("")
    const connectionId = localStorage.getItem('connectionId')
    const handleExecute = () => {
        if(status === 1 || status === 4)
        {
            dispatch(executeTicket({ id: openTicket,  connectionId : connectionId })).then((res) => {
                handleClose();
                if (res?.payload?.status === 200) {
                    setSeverity('success');
                    setMessage('Le Ticket est ouvert ');
                    setOpenSuccessBox(true);
                    props.fetchData(props.paginationModelCustom);
                    props.fetchMessages()
                }
                else {
                    setSeverity('error');
                setMessage('Un problème est survenu');
                setOpenErrorBox(true);
               
                }
            });
        }
        else {
        dispatch(updateTicketState({ Id: openTicket, status: status, connectionId : connectionId })).then((res) => {
            handleClose();
            if (res?.payload?.status === 200) {
                setSeverity('success');
                setMessage('Le Ticket est ouvert ');
                setOpenSuccessBox(true);
                props.fetchData(props.paginationModelCustom);
                props.fetchMessages()
            }
            else {
                setSeverity('error');
            setMessage('Un problème est survenu');
            setOpenErrorBox(true);
           
            }
        });
    }
      // openNewTab(newTab);
       
    }
    
    return (
        <>
        <Dialog open={props.openModal} fullWidth maxWidth='sm'  sx={{
          '& .MuiPaper-root': {
           
            borderRadius: '12px', 
          },
      }}>
            {props.newTicket ? <DialogTitle><H4 title={"Êtes vous sûre de vouloir exécuter ce ticket ?"}></H4></DialogTitle> : 
            <DialogTitle><H4 title={"Êtes vous sûre de vouloir relancer ce ticket ?"}></H4></DialogTitle> }
           
            
            <DialogContent><H5 title={""}></H5></DialogContent>
            <DialogActions>
            <Button  onClick={handleClose} variant="outlined" shape="rounded">Annuler</Button>
            <Button onClick={handleExecute} variant="contained" shape="rounded"
             //color={Colors.primary}
             >Ok</Button>
            </DialogActions>
        </Dialog>
        <Snackbars severity={'success'} message={message} open={openSuccessBox} setOpen={setOpenSuccessBox} autohideduration={1000} />
            <Snackbars severity={'error'} message={message} open={openErrorBox} setOpen={setOpenErrorBox} autohideduration={1000} />
  </>
    )
}