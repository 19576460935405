
import React from 'react';
import { Button, Dialog,  DialogTitle,  } from "@mui/material";

import H4 from "../../GlobalComponents/TypoGraphy/H4";

// import "./style.css";

import { useDispatch } from 'react-redux';

import AlertIcon from '../IconComponents/AlertIcon';
import { NavContext } from '../../App';
import { removeTabById, setLastActiveTab } from '../../ReduxTLK/features/Tabs/TabsSlice';
import { Persistor } from '../..';


export default function GenericDialogCancelledTicket(props) {
    const context = React.useContext(NavContext)

    const dispatch = useDispatch()


    const handleConfirm = () => {
        props.setOpen(false)
        const tabToDeleteIndex = context.tabs.findIndex(
            tab => tab.id === context.activeTab
        );
        const updatedTabs = context.tabs.filter((tab, index) => {
            return index !== tabToDeleteIndex;
        });

        context.setTabs(updatedTabs);
        dispatch(removeTabById(context.activeTab))
        context.setActiveTab(3);
        dispatch(setLastActiveTab(3));
        Persistor.persist()
    }

    return (
        <>
            <Dialog
                open={props.open} maxWidth='md'
                sx={{
                    '& .MuiPaper-root': {
                     
                      borderRadius: '12px', 
                    },
                }}
            >
                <DialogTitle>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 3 }}>
                        <div style={{ marginRight: '10px', marginTop: '20px', marginLeft: '20px' }}>
                            <AlertIcon />
                        </div>
                        <div style={{ marginRight: '20px', marginLeft: '20px', marginTop: '20px' }}>
                            <div style={{ marginBottom: '10px' }}><H4 title={'Avertissement'}></H4></div>
                            <div> <span style={{
                                fontFamily: 'DM Sans',
                                fontSize: '16px',
                                fontWeight: 400,
                                lineHeight: '23px',
                                letterSpacing: '0.15000000596046448px',
                                textAlign: 'left'
                            }}>{props.message}</span></div>

                        </div>
                    </div>
                </DialogTitle>


                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        alignContent: 'center'
                    }}
                >
                    <Button
                        onClick={() => handleConfirm()}
                        variant="contained"
                        shape="rounded"
                        sx={{
                            textTransform: "none",
                            borderRadius: 32,
                            '&.MuiButtonBase-root': {
                                color: '#FFFFFF',
                                backgroundColor: '#2A66C6',
                                borderRadius: '18px',
                                height: '40px',
                                width: '120px',
                                minWidth: '120px',
                                marginLeft: 2,
                                marginBottom: 2.5
                            }
                        }}
                    >
                        Ok
                    </Button>
                </div>

            </Dialog>
        </>
    )
}