import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material"
import Snackbars from "../../../GlobalComponents/Alerts/Snackbars"
import { useDispatch } from "react-redux";
import { Form, Input } from "antd";
import { AddNoteInterne } from "../../../ReduxTLK/features/NotesInternes/NotesInternesSlice";
import { useState } from "react";
import { Colors } from "../../../GlobalComponents/Utility/Colors";
import GridContainer from "../../../GlobalComponents/Grid/GridContainer";
import GridItem from "../../../GlobalComponents/Grid/GridItem";
import H4 from "../../../GlobalComponents/TypoGraphy/H4";
import CloseIcon from '@mui/icons-material/Close';
export default function AddNotesInternes (props) {
    const [open, setOpen] = useState(false)
    const [openErrorBox, setOpenErrorBox] = useState(false);
    const [openSuccessBox, setOpenSuccessBox] = useState(false);
    const [message, setMessage] = useState("")
    const [severity, setSeverity] = useState("success");
    const [note, setNote] = useState();
    const [form] = Form.useForm();
    const { TextArea } = Input;
  
    const dispatch = useDispatch()
     const handleOpenModal = () => {
       setOpen(true)
     }
     const handleClose = () => {
        setOpen(false)
        form.resetFields();
    }
    const handleSave = async (values) => {
        /*************** */
      
        /********** */
        // const textContent = `updateState ticket ${openTicket} oldState ${'en_cours'} newState ${'résolu'} cause ${cause} note ${note}`;
        
        dispatch(AddNoteInterne({idTicket : props.idTicket, ...values})).then((res) => {
            handleClose();
          
            if (res.payload.status === 200) {
                setSeverity('success');
                setMessage('Votre note a été ajouté avec succès ');
                setOpenSuccessBox(true);
                setNote();
                props.fetchData();
            }
            else {
                setSeverity('error');
            setMessage('Un problème est survenu');
            setOpenErrorBox(true);
          
            setNote();
            }
           
            
        });
        form.resetFields();
    
    }
    return (
      <>
         <Button variant="outlined"
            onClick={handleOpenModal}
           
            sx={{
              color: `${Colors.primary}`,
              // margin: '16px',
              display: 'flex',
              width: '180px',
              padding: '10px 0px',
              // marginRight : '-200px',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 'var(--spacing-3xs, 8px)',
              borderRadius: 'var(--units-unit-4, 16px)',
              
              border: '1px solid #2A66C6',

              background: '#FFF',

              boxShadow: '0px 4px 12px -4px rgba(0, 0, 0, 0.12)',
              '&.MuiButtonBase-root': {
                borderRadius: '100px',
                textTransform: 'none',
                fontWeight: 'bold'

              }
            }}
          > Ajouter une note</Button>
         <Dialog open={open} fullWidth
          maxWidth='sn'
          sx={{
            '& .MuiPaper-root': {
             
              borderRadius: '12px', 
            },
        }}
         // sx={{ maxHeight : 500}}
          >
         
                <DialogTitle>
                    <GridContainer>
                        <GridItem sx={{marginTop : '12px'}} sm={11.5} md={11.5} xl={11.5} xxl={11.5}>
                        
                        <H4 title={"Voulez-vous ajoutez une note ?"}></H4>
               
                        </GridItem>
                        {/* <GridItem sx={0.010} sm={0.010} md={0.010} xl={0.010} xxl={0.010}>
              <IconButton sx={{ marginRight: 0 , color:`${Colors.primary}`}} onClick={handleClose}>
                <CloseIcon></CloseIcon>
              </IconButton>
            </GridItem> */}
                    </GridContainer>
                    </DialogTitle>

                <Form
            //{...layout}
            form={form}
            name="add-note"
          //  onValuesChange={onValuesChange}
          //  initialValues={initialValues}
            onFinish={handleSave}
            //ref={props.formRef}
          >
           
                    <GridContainer
                        display='flex'
                        justifyContent='center'
                        alignItems='flex-start'
                        direction='row'
                        gap= 'var(--spacing-3xs, 8px)'
                       // flex= '1 0 0'

                    >
                       

                          
                        <GridItem sx={{marginTop : '-25px'}} sm={12} md={12} xl={12} xxl={12} padding='32px 32px 44px 32px'>

                      
                                    <Typography sx={{
                                        color: 'var(--black, #000)',
                                        fontFamily: 'DM Sans',
                                        fontSize: '16px',
                                        fontStyle: 'normal',
                                        fontWeight: '500',
                                        lineHeight: 'normal',
                                        marginBottom : '10px',
                                    }}>
                                    Note
                                    </Typography>
                                    <Form.Item
                      name="contenuNoteInterne"
                     
                      rules={[
                        { 
                          required: false,
                        //  message: 'Ce champ est obligatoire !',
                        //  maxLength: 350,
                         // validator: validateNonEmptyContent
                        },
                      ]}
                    >
                      <TextArea
                       rows={9} 
                       autoSize={{ minRows: 9, maxRows: 9 }}
                       placeholder="Decrivez..."
                       // maxLength='350' 
                        count={{
                         // show: true,
                          //max: 350,
                        }} 
                        style={{
                           flexWrap : 'wrap',
                           whiteSpace: 'break-spaces',
                           wordWrap:'break-word',
                        }}
                        />
                    </Form.Item>
                                     
                        </GridItem>
                    </GridContainer>
               
                <DialogActions>
                <Form.Item
                style={{ marginTop: '-50px'}}
                //  {...tailLayout}
                 >

<Button
  sx={{
    textTransform: "none",
    border: '2px solid #2A66C6',
    color: '#2A66C6',
    borderColor: '#2A66C6',
    borderRadius: '18px',
    width: '93px',
    height: '40px',
  }}
  onClick={handleClose} variant="outlined" shape="rounded">Annuler</Button>


<Button   type="submit" variant="contained" shape="rounded"
  sx={{
    // size : 'large', 
    textTransform: "none",
   // borderRadius: 32,
    '&.MuiButtonBase-root': {
      color: '#FFFFFF',
      backgroundColor: '#2A66C6',
      borderRadius: '32px',
      // width: '99px',
      height: '40px',
      width: '86px',
      minWidth: '86px',
      marginLeft: 2,
      marginRight : 3,
      boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)'

    }
  }}
//color={Colors.primary}
>Ajouter</Button>

</Form.Item>



</DialogActions>
</Form>

            </Dialog>
            <Snackbars severity={'success'} message={message} open={openSuccessBox} setOpen={setOpenSuccessBox} autohideduration={1000} />
            <Snackbars severity={'error'} message={message} open={openErrorBox} setOpen={setOpenErrorBox} autohideduration={1000} />

      </>
    )
}