///*****dev env */
const BaseUrl = 'https://fi-ne-dev-bomobile-01.frenchinnov.fr';
export const ImageBaseUrl = 'http://40.127.178.155:93/uploads/'
/*****prod env QA*/

//  const BaseUrl = 'https://fi-ne-prod-bomobile-01.azurewebsites.net';
// export const ImageBaseUrl = 'https://fioeatpublicfolder.frenchinnov.fr/uploads/'

/*********set token in localstorage */
export const token =  "Bearer " + localStorage.getItem('Token');
export default BaseUrl; 