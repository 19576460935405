import { Button, Dialog, DialogTitle, DialogActions, DialogContent } from "@mui/material";

import React from 'react';
import H5 from "../../../GlobalComponents/TypoGraphy/H5";
import H4 from "../../../GlobalComponents/TypoGraphy/H4";

import GridItem from "../../../GlobalComponents/Grid/GridItem";
import GridContainer from "../../../GlobalComponents/Grid/GridContainer";
import { useDispatch } from "react-redux";
import { resetNewTicketDraft } from "../../../ReduxTLK/features/SAV/NewTicketDraftSlice";


export default function ConfirmResetForm(props) {
    
    const dispatch = useDispatch()
    const handleClose = () => {
        props.setOpen(false)
       
    }
   
   
    const handleReset = () => {
        props.reset.resetFields();
        props.setSelectedChaine(null);
        props.setSelectedPV(null);
        props.setDirty(false);
        props.setAllFieldsEmpty(true);
        dispatch(resetNewTicketDraft())
        handleClose();
    
    }
   
   
    return (
        <>
         <Dialog open={props.open} fullWidth maxWidth='sm'  sx={{
          '& .MuiPaper-root': {
           
            borderRadius: '12px', 
          },
      }}>
                <DialogTitle sx={{marginLeft : '30px'}}><H4 title={"Êtes vous sur de vouloir annuler l'ajout de votre ticket ?"}></H4></DialogTitle>
                <DialogContent><H5 title={""}></H5>
                </DialogContent>
                
                <DialogActions>
                <GridContainer
                        display='flex-end'
                        justifyContent='right'
                        //alignItems='flex-end'
                        direction='row'
                    >
                        <GridItem sx={2} sm={2} md={2} xl={2} xxl={2} paddingLeft='0px'>
                    <Button    style={{   textTransform: "none", borderRadius:32}}
                    onClick={handleClose} variant="outlined" shape="rounded">Annuler</Button>
                    </GridItem>
                    <GridItem sx={2} sm={2} md={2} xl={2} xxl={2} paddingRight='20px'>
                    <Button    style={{ size : 'large',  textTransform: "none", borderRadius:32, 
                     '&.MuiButtonBase-root': {
                        color: '#2A66C6',
                        borderColor: '#2A66C6',
                        borderRadius: '100px',
                        width: '99px',
                        height: '40px'
                      }
                }}
                    onClick={handleReset} variant="contained" shape="rounded"
                    //color={Colors.primary}
                    >Confirmer</Button>
                      </GridItem>
                      </GridContainer>
                </DialogActions>
            </Dialog>
         
          </>
    )
}