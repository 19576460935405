import { Button, Dialog, DialogTitle, IconButton, DialogActions,  Typography } from "@mui/material";
import { updateTicketState } from "../../../../ReduxTLK/features/SAV/TicketSlice";
import {useDispatch } from 'react-redux';
import React, {useState} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Snackbars from "../../../../GlobalComponents/Alerts/Snackbars";

import H4 from "../../../../GlobalComponents/TypoGraphy/H4";
import { Colors } from "../../../../GlobalComponents/Utility/Colors";

import { NavContext } from '../../../../App';

import { useForm, } from "react-hook-form";
import GridItem from "../../../../GlobalComponents/Grid/GridItem";
import GridContainer from "../../../../GlobalComponents/Grid/GridContainer";

import { sendMessage } from "../../../../ReduxTLK/features/Chat/ChatSlice";

import { unwrapResult } from "@reduxjs/toolkit";
import { Form, Input } from 'antd';



export default function ResolveTicketModal(props) {
    const [form] = Form.useForm();
    const { TextArea } = Input;
    const openTicket = props.openTicket
    const status = 3
    const dispatch = useDispatch()
    const handleClose = () => {
        props.setOpenModal(false)
        form.resetFields();
    }
    const [openErrorBox, setOpenErrorBox] = useState(false);
    const [openSuccessBox, setOpenSuccessBox] = useState(false);
    const context = React.useContext(NavContext)
    const [index, setIndex] = useState(context.maxTabIndex)
    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState("success");
    const [message, setMessage] = useState("")
    const [cause, setCause] = useState();
    const [note, setNote] = useState();
    const [errorCause, setErrorCause] = useState(false)
    const connectionId = localStorage.getItem('connectionId')

    const handleExecute = async (values) => {
        /*************** */
      
        /********** */
        // const textContent = `updateState ticket ${openTicket} oldState ${'en_cours'} newState ${'résolu'} cause ${cause} note ${note}`;
        const textContent = `Le ticket ${openTicket} a été résolu. ${values.note ? values.note : ''}`;
        dispatch(updateTicketState({ Id: openTicket, status: status, note : values.note, connectionId : connectionId })).then((res) => {
            handleClose();
            if (res.payload.status === 200) {
                setSeverity('success');
                setMessage('Le Ticket est résolu ');
                setOpenSuccessBox(true);
                setCause('');
                setNote();
                props.fetchData();
                props.fetchMessages()
            }
            else {
            setSeverity('error');
            setMessage('Un problème est survenu');
            setOpenErrorBox(true);
            setCause('');
            setNote();
            }
           
            
        });
        form.resetFields();
    
    }
    const { register, control, handleSubmit, reset, formState: { errors } } = useForm({})
    const [formData, setFormData] = useState({})
    const handleChange = (event) => {
        const { name, value } = event.target;
       
           
            setNote(value);
       
    };
    return (
        <>
         <Dialog open={props.openModal} fullWidth maxWidth='sm'  sx={{
          '& .MuiPaper-root': {
           
            borderRadius: '12px', 
          },
      }}>
                <DialogTitle>
                    <GridContainer>
                        <GridItem sx={11.5} sm={11.5} md={11.5} xl={11.5} xxl={11.5}>
                        <H4 title={"Êtes vous sûre de vouloir resoudre ce ticket ?"}></H4>
               
                        </GridItem>
                        <GridItem sx={0.010} sm={0.010} md={0.010} xl={0.010} xxl={0.010}>
              <IconButton sx={{ marginRight: 0 , color:`${Colors.primary}`}} onClick={handleClose}>
                <CloseIcon></CloseIcon>
              </IconButton>
            </GridItem>
                    </GridContainer>
                    </DialogTitle>

                <Form
            //{...layout}
            form={form}
            name="control-hooks-resolveTicket"
          //  onValuesChange={onValuesChange}
          //  initialValues={initialValues}
            onFinish={handleExecute}
            ref={props.formRef}
          >
           
                    <GridContainer
                        display='flex'
                        justifyContent='center'
                        alignItems='flex-start'
                        direction='row'
                        gap= 'var(--spacing-3xs, 8px)'
                        flex= '1 0 0'

                    >
                       

                          
                        <GridItem sx={12} sm={12} md={12} xl={12} xxl={12} padding='32px 32px 44px 32px'>

                      
                                    <Typography sx={{
                                        color: 'var(--black, #000)',
                                        fontFamily: 'Inter',
                                        fontSize: '16px',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        lineHeight: 'normal',
                                        marginBottom : '10px',
                                    }}>
                                    Descriptions
                                    </Typography>
                                    <Form.Item
                      name="note"
                     
                      rules={[
                        { 
                          required: false,
                        //  message: 'Ce champ est obligatoire !',
                        //  maxLength: 350,
                         // validator: validateNonEmptyContent
                        },
                      ]}
                    >
                      <TextArea
                       rows={9} 
                       autoSize={{ minRows: 9, maxRows: 9 }}
                       placeholder="Decrivez..."
                       // maxLength='350' 
                        count={{
                         // show: true,
                          //max: 350,
                        }} />
                    </Form.Item>
                                     
                        </GridItem>
                    </GridContainer>
               
                <DialogActions>
                <Form.Item
                style={{ marginTop: '-25px'}}
                //  {...tailLayout}
                 >

<Button
  sx={{
    textTransform: "none",
    border: '2px solid #2A66C6',
    color: '#2A66C6',
    borderColor: '#2A66C6',
    borderRadius: '18px',
    width: '93px',
    height: '40px',
  }}
  onClick={handleClose} variant="outlined" shape="rounded">Annuler</Button>


<Button   type="submit" variant="contained" shape="rounded"
  sx={{
    
    textTransform: "none",
   
    '&.MuiButtonBase-root': {
      color: '#FFFFFF',
      backgroundColor: '#2A66C6',
      borderRadius: '32px',
    
      height: '40px',
      width: '160px',
      minWidth: '100px',
      marginLeft: 2,
      marginRight : 3,
      boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)'

    }
  }}

>Sauvegarder</Button>

</Form.Item>



</DialogActions>
</Form>
            </Dialog>
            <Snackbars severity={'success'} message={message} open={openSuccessBox} setOpen={setOpenSuccessBox} autohideduration={1000} />
            <Snackbars severity={'error'} message={message} open={openErrorBox} setOpen={setOpenErrorBox} autohideduration={1000} />
 </>
    )
}