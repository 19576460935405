import { Button, Dialog, DialogTitle, DialogActions, DialogContent } from "@mui/material";
import { executeTicket } from "../../../ReduxTLK/features/SAV/TicketSlice";
import {useDispatch } from 'react-redux';
import React, {useState} from 'react';
import Snackbars from "../../../GlobalComponents/Alerts/Snackbars";

import H4 from "../../../GlobalComponents/TypoGraphy/H4";
import { NavContext } from '../../../App';
import routes from "../../../routes";
import EditSAVTicket from "../TicketDetails/Edit/EditSAVTicket";
import SAVIcon from "../../../GlobalComponents/IconComponents/SAVIcon";
import { addTab, setLastActiveTab } from '../../../ReduxTLK/features/Tabs/TabsSlice';
import { Persistor } from '../../..';
export default function ExecuteTicketModal(props) {
    const openTicket = props.openTicket
    const status = 2
    const dispatch = useDispatch()
    const handleClose = () => {
        props.setOpenModal(false)

    }
    const context = React.useContext(NavContext)
    const [index, setIndex] = useState(context.maxTabIndex)
    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState("success");
    const [message, setMessage] = useState("")
    const connectionId = localStorage.getItem('connectionId')
    const handleExecute = () => {
        dispatch(executeTicket({ id: openTicket, connectionId : connectionId }))
        .then((res) => {
            handleClose();
           
            if (res.payload.status === 200) {
                setSeverity('success');
                setMessage('Le Ticket est ouvert ');
                setOpen(true);
                props.fetchData(props.paginationModelCustom);
            }
            else {
            setSeverity('error');
            setMessage('Un problème est survenu');
            setOpen(true);
           
            }
            
        });
       openNewTab({newTab, openTicket});
       
    }
    const newTab = routes && routes[0].pages[1].views[2];
   
    function openNewTab(props) {
        const title = '#' + props.openTicket;
    const composedId = 'SAV_Ticket_' + props.openTicket 
    // Find any existing tab with the same ID
    const existingTabIndex = context.tabs.findIndex((tab) => tab.id === composedId);
  
    // If a tab with the ID already exists, activate it
    if (existingTabIndex !== -1) {
      context.setActiveTab(composedId);
      dispatch(setLastActiveTab(composedId));
      return; // Exit the function to avoid creating a duplicate tab
    }
  
    // If no existing tab found, create a new one
   // setIndex(composedId);
    dispatch(addTab({
      module : 'SAV',
      action : 'Edit',
      identifier : props.openTicket,
      id: composedId,
      label: title,
    }));
    
    context.setTabs([...context.tabs, {
      id: composedId,
      label: title,
      identifier : props.openTicket,
      component: <EditSAVTicket id={props.openTicket}/>,
      closeable: true,
      icon: <SAVIcon />,
    }]);
    Persistor.persist();
   // context.setMaxTabIndex(index + 1);
    context.setActiveTab(composedId);
    dispatch(setLastActiveTab(composedId));
       // setOpen(open);
      };
    return (
        <>
        <Dialog open={props.openModal} fullWidth maxWidth='sm'  sx={{
          '& .MuiPaper-root': {
           
            borderRadius: '12px', 
          },
      }}>
            <DialogTitle><H4 title={"Êtes vous sûre de vouloir exécuter ce ticket ?"}></H4></DialogTitle>
            <DialogContent>
                {/* <H5 title={"Si vous exécutez, il vous sera assigné automatiquement !"}></H5> */}
                </DialogContent>
            <DialogActions>
            <Button  onClick={handleClose} variant="outlined" shape="rounded">Annuler</Button>
            <Button onClick={handleExecute} variant="contained" shape="rounded"
             //color={Colors.primary}
             >Ok</Button>
            </DialogActions>
        </Dialog>
          <Snackbars severity={severity} message={message} open={open} setOpen={setOpen} autoHideDuration={1000} />
          </>
    )
}