
import React, { useContext, useState, } from 'react';
import { Button, Dialog, DialogContent, IconButton, DialogTitle, DialogActions } from "@mui/material";
import GridContainer from "../../GlobalComponents/Grid/GridContainer";
import GridItem from "../../GlobalComponents/Grid/GridItem";
import H4 from "../../GlobalComponents/TypoGraphy/H4";
import CloseIcon from '@mui/icons-material/Close';

import { useDispatch } from 'react-redux';


import Snackbars from '../../GlobalComponents/Alerts/Snackbars';

import { NavContext } from '../../App';
import { Persistor } from '../..';
import { removeTab, setLastActiveTab } from '../../ReduxTLK/features/Tabs/TabsSlice';
import { resetEditTicketDraftByIndex } from '../../ReduxTLK/features/SAV/NewTicketDraftSlice';
import { Colors } from '../Utility/Colors';

export default function GenericDialogEditTicketSaveConfirmation(props) {

    //const [open, setOpen] = useState(false)
    const [message,] = useState()
    const [openErrorBox, setOpenErrorBox] = useState(false)
    const [openSuccessBox, setOpenSuccessBox] = useState(false)

    const context = useContext(NavContext)
    const dispatch = useDispatch()

    const handleClose = () => {
        props.setOpen(false)

    }

    const handleConfirm = () => {


        dispatch(resetEditTicketDraftByIndex(props.tabToBeDelete.identifier))
        const tabToDeleteIndex = context.tabs.findIndex(
            tab => tab.id === props.tabToBeDelete.id
        );
        const updatedTabs = context.tabs.filter((tab, index) => {
            return index !== tabToDeleteIndex;
        });
        context.setTabs(updatedTabs);


        const previousTab =
            context.tabs[tabToDeleteIndex - 1] ||
            context.tabs[tabToDeleteIndex + 1] ||
            {};
        props.setActiveTabs(updatedTabs);
        context.setActiveTab(previousTab.id);

        dispatch(setLastActiveTab(previousTab.id));
        dispatch(removeTab(props.tabToBeDelete))
        Persistor.persist()
        props.setOpen(false)
        return;
    }

    return (
        <>

            <Dialog
                open={props.open} onClose={handleClose} maxWidth='md'
                sx={{
                    '& .MuiPaper-root': {
                     
                      borderRadius: '12px', 
                    },
                }}
            >
                <br></br>
                <DialogTitle>
                    <div style={{
                        display: 'flex',
                        direction : 'column',
                    }}>
                        <div>  <span style={{
                            fontFamily: 'DM Sans',
                            fontSize: '18px',
                            fontWeight: 700,
                            lineHeight: '28px',
                            textAlign: 'left'
                            
                        }}>Êtes vous sur de vouloir  quitter sans sauvegarder les</span>
                            <br></br>
                            <span style={{
                            fontFamily: 'DM Sans',
                            fontSize: '18px',
                            fontWeight: 700,
                            lineHeight: '28px',
                            textAlign: 'left'
                            
                        }}>informations ajoutées ?</span>
                        </div>
                        <div style={{ minWidth :'200px'}}></div>
                        {/* <div>
                            <IconButton sx={{ marginRight: 0, color: Colors.primary }} onClick={handleClose}>
                                <CloseIcon></CloseIcon>
                            </IconButton>
                        </div> */}
                    </div>







                </DialogTitle>
                <DialogContent>

                    <>

                    </>


                </DialogContent>
                <DialogActions>


                    <Button
                        sx={{
                            textTransform: "none",
                            border: '2px solid #2A66C6',
                            color: '#2A66C6',
                            borderColor: '#2A66C6',
                            borderRadius: '18px',
                            width: '93px',
                            height: '40px',
                        }}
                        onClick={handleClose} variant="outlined" shape="rounded">Annuler</Button>


                    <Button
                        onClick={() => handleConfirm()}
                        variant="contained" shape="rounded"
                        sx={{
                            // size : 'large', 
                            textTransform: "none",
                            borderRadius: 32,

                            '&.MuiButtonBase-root': {
                                color: '#FFFFFF',
                                backgroundColor: '#2A66C6',
                                borderRadius: '18px',
                                // width: '99px',
                                height: '40px',
                                width: '120px',
                                minWidth: '120px',
                                marginLeft: '10px',
                                marginRight: '20px'

                            }
                        }}
                    //color={Colors.primary}
                    >Continuer</Button>
                </DialogActions>
            </Dialog>
            <Snackbars severity={'success'} message={message} open={openSuccessBox} setOpen={setOpenSuccessBox} autoHideDuration={1000} />
            <Snackbars severity={'error'} message={message} open={openErrorBox} setOpen={setOpenErrorBox} autoHideDuration={1000} />
        </>
    )
}