import { Button, Dialog, DialogTitle, DialogActions, DialogContent } from "@mui/material";
import { executeTicket, fetchTicketById, updateTicketState } from "../../../../ReduxTLK/features/SAV/TicketSlice";
import { useDispatch } from 'react-redux';
import React, { useState } from 'react';
import Snackbars from "../../../../GlobalComponents/Alerts/Snackbars";

import H4 from "../../../../GlobalComponents/TypoGraphy/H4";

import { NavContext } from '../../../../App';

import EditSAVTicket from "../Edit/EditSAVTicket";
import SAVIcon from "../../../../GlobalComponents/IconComponents/SAVIcon";
import { addTab, removeTabById, setLastActiveTab } from '../../../../ReduxTLK/features/Tabs/TabsSlice';

import { unwrapResult } from "@reduxjs/toolkit";
import { Persistor } from "../../../..";
import { resetEditTicketDraftByIndex } from "../../../../ReduxTLK/features/SAV/NewTicketDraftSlice";
export default function ExecuteTicketModalInsideView(props) {
  const openTicket = props.openTicket

  const dispatch = useDispatch()
  const handleClose = () => {
    props.setOpenModal(false)

  }
  const context = React.useContext(NavContext)
  const [index, setIndex] = useState(context.maxTabIndex)
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [message, setMessage] = useState("")
  const connectionId = localStorage.getItem('connectionId')
  const handleExecute = () => {
    dispatch(executeTicket({ id: openTicket, connectionId: connectionId })).then((res) => {
      handleClose();

      if (res.payload.status === 200) {
        const composedId = 'SAV_Ticket_' + openTicket
        const title = '#' + openTicket;
        setSeverity('success');
        setMessage('Le Ticket est en cours ');
        setOpen(true);
        props.setDirty(false);
        dispatch(resetEditTicketDraftByIndex(openTicket))
        Persistor.persist();
        dispatch(fetchTicketById(openTicket))
          .then(unwrapResult)
          .then((res) => {
            props.setTicket(res);

            const updatedTabs = context.tabs.filter((tab) => {

              return tab.identifier !== openTicket;
            });

            dispatch(removeTabById(composedId))

            context.setTabs([...updatedTabs, {
              id: composedId,
              label: title,
              identifier: openTicket,
              component: <EditSAVTicket id={openTicket} />,
              closeable: true,
              icon: <SAVIcon />,
            }]);
            dispatch(addTab({
              module: 'SAV',
              action: 'Edit',
              identifier: openTicket,
              id: composedId,
              label: title,
            }));
            Persistor.persist();

            context.setActiveTab(composedId);
            dispatch(setLastActiveTab(composedId));



          })
      }
      else {
        setSeverity('error');
        setMessage('Un problème est survenu');
        setOpen(true);

      }

    });


  }

  return (
    <>
      <Dialog open={props.openModal} fullWidth maxWidth='sm'  sx={{
          '& .MuiPaper-root': {
           
            borderRadius: '12px', 
          },
      }}>
        <DialogTitle><H4 title={"Êtes vous sûre de vouloir exécuter ce ticket ?"}></H4></DialogTitle>
        <DialogContent>
          {/* <H5 title={"Si vous exécutez, il vous sera assigné automatiquement !"}></H5> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" shape="rounded">Annuler</Button>
          <Button onClick={handleExecute} variant="contained" shape="rounded"
          //color={Colors.primary}
          >Ok</Button>
        </DialogActions>
      </Dialog>
      <Snackbars severity={severity} message={message} open={open} setOpen={setOpen} autoHideDuration={1000} />
    </>
  )
}