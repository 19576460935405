import React, { useState, useEffect, useRef } from "react";
import H4 from "../../../../GlobalComponents/TypoGraphy/H4";
import { Colors } from "../../../../GlobalComponents/Utility/Colors";
import GridItem from "../../../../GlobalComponents/Grid/GridItem";
import GridContainer from "../../../../GlobalComponents/Grid/GridContainer";
import { useDispatch, useSelector } from "react-redux";
import { Form, } from 'antd';
import {
  fetchTicketById,

} from "../../../../ReduxTLK/features/SAV/TicketSlice";
import { getMessages, getNbrMessagesNonLus, } from "../../../../ReduxTLK/features/Chat/ChatSlice";

import { Button, Box, Card, CardContent, Tab, Tabs, Tooltip, } from "@mui/material";
import { useForm, } from "react-hook-form";
import { unwrapResult } from '@reduxjs/toolkit'
import { fetchUsersAgentSAV } from "../../../../ReduxTLK/features/User/UserSlice";
import { fetchChaines } from "../../../../ReduxTLK/features/Chaines/ChaineSlice";
import { fetchPventes } from "../../../../ReduxTLK/features/Pointdeventes/PointdeventeSlice";

import { NavContext } from '../../../../App';

import { array_status } from '../../../../ReduxTLK/features/SAV/ticketList';
import ExecuteIcon from "../../../../GlobalComponents/IconComponents/ExcecuteIcon";
import SuspendIcon from "../../../../GlobalComponents/IconComponents/SuspendIcon";
import ResolvedIcon from "../../../../GlobalComponents/IconComponents/ResolvedIcon";
import ExecuteTicketInsideUpdateModal from './ExecuteTicketInsideUpdateModal';
import MettreEnAttenteTicketModal from "./MettreEnAttenteTicketModal";
import ResolveTicketModal from "./ResolveTicketModal";
import HomeIcon from "../../../../GlobalComponents/IconComponents/HomeIcon";

import ChatComponent from "../../../../GlobalComponents/Chat/Chat";
import BaseUrl, { ImageBaseUrl } from '../../../../assets/utils/accessToken'
import signalRService from '../../../../GlobalComponents/Utility/SignalRConnection';
import { random, sortBy } from 'lodash'
import placeholder from "../../../../assets/images/placeholder.png"
import AttachmentList from "../../Attachements/AttachmentList";
import TicketDetails from "./TicketDetails";
import TabContainer from "../../../../GlobalComponents/TabsContainer/TabContainer";
import { setPaginationModelSAVTA } from "../../../../ReduxTLK/features/Pagination/PaginationsSlice";
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { getAttachementList, getNbreAttachementNotSeen } from "../../../../ReduxTLK/features/Attachement/AttachementSlice";

import AttachementCounter from "../../Attachements/AttachementCounter";
import { Persistor } from "../../../..";
import { addTab, removeTabById, setLastActiveTab } from "../../../../ReduxTLK/features/Tabs/TabsSlice";
import SAVIcon from "../../../../GlobalComponents/IconComponents/SAVIcon";
import ViewSAVTicket from "../View/ViewSAVTicket";




export default function EditSAVTicket({ id }) {
  const context = React.useContext(NavContext)
  const [nbreAttachementsNonLus, setNbreAttachementsNonLus] = useState(null)
  const [logoAvailable, setLogoAvailable] = useState(false)
  ////setting signalr join room and receive message listner
  useEffect(() => {
  
    if (context.roomId === id)

      setMessagesList((prevMessagesList) => {
        const message = {
          id: random(),
          attachement: context.receivedMessage?.attachement,
          contenu: context.receivedMessage?.contenu,
          date: context.receivedMessage?.dateEnvoie,
          messageUpdateStatut: {
            action: {
              userFirstName: context?.receivedMessage?.messageUpdateStatut?.action?.userFirstName,
              idTicket: context?.receivedMessage?.messageUpdateStatut?.action?.idTicket,
              firstStatuts: context?.receivedMessage?.messageUpdateStatut?.action?.firstStatuts,
              secondStatuts: context?.receivedMessage?.messageUpdateStatut?.action?.secondStatuts
            },
            cause: context?.receivedMessage?.messageUpdateStatut?.cause,
            note: context?.receivedMessage?.messageUpdateStatut?.note,
          },


          sender: {
            id: context.receivedMessage?.idSender,
            nom: context.receivedMessage?.name,
          },
        };
       
        return [...prevMessagesList, message];
      });
    signalRService.onJoinRoom(id);

    signalRService.onReceiveMessage((receivedMessage) => {
    
      context.setReceivedMessage(receivedMessage)
      context.setRoomId(id)
      context.setIsInRoom(true)


    });



  }, [context.receivedMessage]);
  ////updating nbre of new messages 
  useEffect(() => {

    //  joinRoomFunction(id);
    if (context.roomId === id) {

      const data = {
        idTicket: id,
        page: 1,
        pageSize: 1000,
      };

      dispatch(getNbrMessagesNonLus(data)).then((res) => {
        if (!openChatBox) {
          setNbreMessagesNonLus(res?.payload?.data?.data?.result);
        } else {
          setNbreMessagesNonLus(0);
        }
      });
    }
    if (context.receivedMessage?.attachement?.id !== undefined && context.receivedMessage?.attachement?.id !== null) {
      setNbreAttachementsNonLus(nbreAttachementsNonLus + 1)
    }




  }, [context.receivedMessage]);
  const [dirty, setDirty] = useState(false)
  const [loading, setLoading] = useState(true);
  const formRef = useRef()
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [ticketDetails, setTicket] = useState();

  const composedId = 'SAV_Ticket_' + id
  const [activeTab, setActiveTab] = useState(context.activeTab);
  const [isConnected, setIsConnected] = useState(context.isConnected);
  const isUnmounted = useRef(context.isUnmounted);
  const [messagesList, setMessagesList] = useState([])
  const [nbreMessagesNonLus, setNbreMessagesNonLus] = useState([])
  const [toReload, setToReload] = useState(context.tabToBeReloaded)
  const [reloadAfterNotifClick, setReloadAfterNotifClick] = useState(context.tabToBeReloadedNotif)
  useEffect(() => {
    setToReload(context.tabToBeReloaded)
  }, [context.tabToBeReloaded])
  // const id  = context.selectedTicket
  const dispatch = useDispatch();
  const { register, control, handleSubmit, formState: { errors } } = useForm({});
  const [formData, setFormData] = useState(
    {
      //  ref: "",
      idChaine: "",
      idPv: "",
      type: -1,
      sujet: "",
      priorite: -1,
      intervenant: "",
      description: "",
      machine: -1,
      idAssigne: "0",
      impact: -1,
      télephone: "",
      intervenantId: "",
    });
  //const toReload = context.tabToBeReloaded

  const tabToBeReloadedAttachement = context.tabToBeReloadedAttachement
  useEffect(() => {
    if (parseInt(tabToBeReloadedAttachement) === parseInt(id)) {
      dispatch(getNbreAttachementNotSeen(id)).then(unwrapResult).then((res) => {

        setNbreAttachementsNonLus(res.data.data.result)
      }).then(() => {
        if (value === 2) {
          dispatch(getAttachementList({
            pageSize: 7,
            page: 1,
            nbPage: 0,
            idTicket: id
          }))
            .then(unwrapResult).then((res) => {
              setAttachmentList(res?.data?.data?.result)
              setPaginationModelCustom({
                pageSize: res?.data?.data?.pagination?.pageSize,
                page: res?.data?.data?.pagination?.currentPage,
                nbPage: res?.data?.data?.pagination?.nbPage,
                idTicket: ticketDetails?.id
              })
              dispatch(setPaginationModelSAVTA({
                page: res?.data?.pagination?.currentPage,
                pageSize: res?.data?.pagination?.pageSize,
                nbPage: res?.data?.pagination?.nbPage,
                idTicket: ticketDetails?.id
              }))
              Persistor.persist();
            }
            );
        }

      })

    }
  }, [context.tabToBeReloadedAttachement])
  useEffect(() => {

    setReloadAfterNotifClick(context.tabToBeReloadedNotif)
  }, [context.tabToBeReloadedNotif])
  useEffect(() => {

    if (reloadAfterNotifClick) {

      if (parseInt(context.tabToBeReloadedNotif) === parseInt(id)) {
        setValue(1)
        context.setTabToBeReloadedNotif()
      }
    }

  }, [reloadAfterNotifClick])

  useEffect(() => {

    if (context.tabToBeReloaded) {

      if (parseInt(context.tabToBeReloaded) === parseInt(id) && reloadAfterNotifClick === id) {

        setValue(1)
        dispatch(fetchTicketById(id))
          .then(unwrapResult)
          .then((res) => {

            setTicket(res);
            setPageTitle(`${symbol}${res?.id}`);
            setCanExecute(false);
            setCanExecuteOpen(false);
            setNewTicket(true);
            setEnCours(false);
            setResolved(false);
            setCanExecute(false);
            context.setTabToBeReloaded()
            switch (res?.statut) {
              case 0: return setNewTicket(true);
              case 1: return setCanExecute(true);
              case 2: return setEnCours(true);
              case 3: return setResolved(true);
              case 4: return setCanExecute(true);
              default: return null;
            }

          });
      }
      if (parseInt(context.tabToBeReloaded) === parseInt(id) && !reloadAfterNotifClick === id || parseInt(context.tabToBeReloaded) === parseInt(id) && reloadAfterNotifClick === undefined) {

        setValue(1)
        dispatch(fetchTicketById(id))
          .then(unwrapResult)
          .then((res) => {
            
            if(res?.statut === parseInt(6))
            {
              const composedId = 'SAV_Ticket_' + res?.id
              const title = '#' + res?.id;  
              setTicket(res);
            const updatedTabs = context.tabs.filter((tab) => {
             
              return tab.identifier !== res?.id;
            });

            dispatch(removeTabById(composedId))
          
            context.setTabs([...updatedTabs, {
              id: composedId,
              label: title,
              identifier: res?.id,
              component: <ViewSAVTicket id={res?.id} />,
              closeable: true,
              icon: <SAVIcon />,
            }]);
            dispatch(addTab({
              module: 'SAV',
              action: 'View',
              identifier: res?.id,
              id: composedId,
              label: title,
            }));
            Persistor.persist();

            context.setActiveTab(composedId);
            dispatch(setLastActiveTab(composedId));
           
          }
          else {
          
            setTicket(res);
            setPageTitle(`${symbol}${res?.id}`);
            setCanExecute(false);
            setCanExecuteOpen(false);
            setEnCours(false);
            setResolved(false);
            setCanExecute(false);
            context.setTabToBeReloaded()
            
           
            switch (res?.statut) {
              case 0: return setCanExecuteOpen(true);
              case 1: return setCanExecute(true);
              case 2: return setEnCours(true);
              case 3: return setResolved(true);
              case 4: return setCanExecute(true);
              default: return null;
            }

          
        }
          });
        
       
        
        }

      
    }

  }, [context.tabToBeReloaded])

  useEffect(() => {
    dispatch(fetchUsersAgentSAV());
    dispatch(fetchChaines());

  }, []);
  const symbol = '#';
  const [pageTitle, setPageTitle] = useState()
  const [etablissement, setEtablissement] = useState()
  const fetchMessages = async () => {
    const data = {
      idTicket: ticketDetails?.id,
      page: 1,
      pageSize: 1000
    }
    try {
      
      dispatch(getMessages(data)).then(unwrapResult).then((res) => {
        const sortedMessages = sortBy(res.data?.data?.result, 'date')

        setMessagesList(sortedMessages)
      })
    } catch (e) {
    
    }
  };


  useEffect(() => {

    setTimeout(() => {
      dispatch(fetchTicketById(id))
        .then(unwrapResult)
        .then((res) => {

          setTicket(res);
          setLoading(false);
          dispatch(getNbreAttachementNotSeen(id)).then(unwrapResult).then((res) => {

            setNbreAttachementsNonLus(res.data.data.result)
          })
          const data = {
            idTicket: res?.id,
            page: 1,
            pageSize: 1000
          }
          try {
            dispatch(getNbrMessagesNonLus(data)).then((res) => {
              if (!openChatBox) {
                setNbreMessagesNonLus(res?.payload?.data?.data?.result);
              }
              else {
                setNbreMessagesNonLus(0);
              }

            }).then(() =>

              dispatch(getMessages(data)).then(unwrapResult).then((res) => {
                const sortedMessages = sortBy(res.data?.data?.result, 'date')

                setMessagesList(sortedMessages)
                //  splitMessagesList(sortedMessages, nbreMessagesNonLus)
              }))

          } catch (e) {
           
          }

          setPageTitle(`${symbol}${res?.id}`);
          setEtablissement(res?.chaine.id);
          const getintervenant = res?.intervenantFirstname + ' ' + res?.intervenantLastname


          form.setFieldsValue({
            etablissement: res?.idChaine,
            pointDeVente: res?.idPv,
            machine: res?.machine,
            type: res?.type,
            sujet: res?.sujet,
            priorite: res?.priorite,
            intervenant: getintervenant,
            impact: res?.impact,
            idAssignee: res?.idAssigne,
            telephone: res?.intervenantPhone,
            description: res?.description

          })

          dispatch(fetchPventes(res?.idChaine));
          switch (res?.statut) {
            case 0: return setNewTicket(true);
            case 1: return setCanExecute(true);
            case 2: return setEnCours(true);
            case 3: return setResolved(true);
            case 4: return setCanExecute(true);
            default: return null;
          }

        });
    }, 1000);

    return () => {
      setNbreAttachementsNonLus(0);
    };
  }, []);

  const [requiredFieldsSet, setRequiredFieldsSet] = useState(false);

  useEffect(() => {
    const checkRequiredFields = () => {
      const areRequiredFieldsSet = (
        ticketDetails?.idChaine !== null &&
        ticketDetails?.idChaine !== undefined &&
        ticketDetails?.idPv !== null &&
        ticketDetails?.idPv !== undefined &&
        ticketDetails?.machine !== null &&
        ticketDetails?.machine !== undefined &&
        ticketDetails?.type !== null &&
        ticketDetails?.type !== undefined &&
        ticketDetails?.sujet !== null &&
        ticketDetails?.sujet !== undefined &&
        ticketDetails?.sujet !== "" &&
        ticketDetails?.description !== null &&
        ticketDetails?.description !== "" &&
        ticketDetails?.description !== undefined
        // ... add other fields as needed
      );

      setRequiredFieldsSet(areRequiredFieldsSet);
    };

    checkRequiredFields();
  }, [ticketDetails]);
  const fetchData = async () => {
    dispatch(fetchTicketById(ticketDetails.id))
      .then(unwrapResult)
      .then((res) => {
        setTicket(res);
        setLoading(false);
        setPageTitle(`${symbol}${res?.id}`);
        setCanExecute(false);
        setNewTicket(false)
        setCanExecuteOpen(false);
        setEnCours(false);
        setResolved(false);
        setCanExecute(false);

        switch (res?.statut) {
          case 0: return setNewTicket(true);
          case 1: return setCanExecute(true);
          case 2: return setEnCours(true);
          case 3: return setResolved(true);
          case 4: return setCanExecute(true);
          default: return null;
        }
      });
  }
  //  useEffect(() => {context.activeTab
  //  if(ticketDetails?.id ==! undefined )
  //   {
  //     fetchMessages();
  //   }

  //  }, [ticketDetails])
  useEffect(() => {
    if (etablissement == !undefined || etablissement == ! null) {
      dispatch(fetchPventes(etablissement));
    }
  }, [etablissement])
  const handleCheckRequiredFields = async (form) => {

    // Validate the required fields from the parent component
    try {
      await form.validateFields(['etablissement', 'pointDeVente', 'machine', 'type', 'sujet', 'description', 'priorite', 'idAssignee']);

      setRequiredFieldsSet(true);
    } catch (errorInfo) {
     
    }
  };
  const agentsSAV = useSelector((state) => state.user.agentsSAV)
  const [pVName, setPVName] = useState("")
  const chaines = useSelector((state) => state.chaine.chaines)
  const pointDeVentes = useSelector((state) => state.pointDeVente.pventes)
  const getItemDetails = (item) => `${item.firstName} ${item.lastName}`;
  const [openChatBox, setOpenChatBox] = useState(false)
  const GetStatutChip = () => {
    const foundStatut = array_status.find(statut => statut.id == ticketDetails?.statut);
    if (!foundStatut) {
      return '';
    }
    return (
      <Button
        variant='outlined'
        disabled={true}
        sx={{
          marginTop: '10px',
          //marginRight: "500px",
          textTransform: "none",
          borderRadius: 32,

          //  padding:'12px, 5px, 12px, 10px',
          '&.MuiButtonBase-root': {
            color: foundStatut.backgroundColor,
            borderColor: foundStatut.backgroundColor,
            borderRadius: '100px',
            width: '120px',
            height: '30px',
            border: '2px solid',
            fontWeight: 'bold'

          }
        }}
      //color="primary"
      > {foundStatut.nom}
      </Button>
      // <StyledChip label={foundStatut.nom} bgcolor={foundStatut.backgroundColor} />
    );
  };
  const [canExecute, setCanExecute] = useState(false);
  const [canExecuteOpen, setCanExecuteOpen] = useState(false);
  const [enCours, setEnCours] = useState(false);
  const [newTicket, setNewTicket] = useState(false);
  const [resolved, setResolved] = useState(false);
  const [openModalExecute, setOpenModalExecute] = useState(false)
  const [openModalSuspend, setOpenModalSuspend] = useState(false)
  const [openModalResolve, setOpenModalResolve] = useState(false)
  const handleExecuteOpen = async () => {
    //setRowSelectionModel(ticket.id)
    // setOpenModalExecute(true);
    // setOpenModalResolve(false);
    // setOpenModalSuspend(false);
    setValue(1);

    try {
      // Wait for setValue to finish before proceeding
      await new Promise(resolve => setTimeout(resolve, 1000)); // Add a small delay or use another mechanism if needed

      // Now, you can access the formRef and validateFields
      formRef.current.validateFields()
        .then((values) => {
          if (dirty) {

            formRef.current.submit(values);
            setOpenModalResolve(false);
            setOpenModalExecute(true);
            setOpenModalSuspend(false);
          } else {
            setOpenModalResolve(false);
            setOpenModalExecute(true);
            setOpenModalSuspend(false);
          }
        })
        .catch((errorInfo) => {
         
        });
    } catch (error) {
     
    }


  }
  const handleExecute = async () => {
    //setRowSelectionModel(ticket.id)
    // setOpenModalExecute(true);
    // setOpenModalResolve(false);
    // setOpenModalSuspend(false);
    setValue(1);

    try {
      // Wait for setValue to finish before proceeding
      await new Promise(resolve => setTimeout(resolve, 1000)); // Add a small delay or use another mechanism if needed

      //  access the formRef and validateFields
      formRef.current.validateFields()
        .then((values) => {
          if (dirty) {

            formRef.current.submit(values);
            setOpenModalResolve(false);
            setOpenModalExecute(true);
            setOpenModalSuspend(false);
          } else {
            setOpenModalResolve(false);
            setOpenModalExecute(true);
            setOpenModalSuspend(false);
          }
        })
        .catch((errorInfo) => {
        
        });
    } catch (error) {
     
    }


  }

  const handleSuspend = async () => {
    setValue(1);

    try {
      // Wait for setValue to finish before proceeding
      await new Promise(resolve => setTimeout(resolve, 1000)); // Add a small delay or use another mechanism if needed

      // Now, you can access the formRef and validateFields
      formRef.current.validateFields()
        .then((values) => {

          if (dirty) {
            formRef.current.submit(values);
            setOpenModalResolve(false);
            setOpenModalExecute(false);
            setOpenModalSuspend(true);
          } else {
            setOpenModalResolve(false);
            setOpenModalExecute(false);
            setOpenModalSuspend(true);
          }
        })
        .catch((errorInfo) => {
         
        });
    } catch (error) {
      
    }
  };

  const handleResolve = async () => {
    setValue(1);

    try {
      // Wait for setValue to finish before proceeding
      await new Promise(resolve => setTimeout(resolve, 1000)); // Add a small delay or use another mechanism if needed

      // Now, you can access the formRef and validateFields
      formRef.current.validateFields()
        .then((values) => {


          if (dirty) {
            formRef.current.submit(values);
            setOpenModalExecute(false);
            setOpenModalSuspend(false);
            setOpenModalResolve(true);
          } else {
            setOpenModalExecute(false);
            setOpenModalSuspend(false);
            setOpenModalResolve(true);
          }
        })
        .catch((errorInfo) => {
         
        });
    } catch (error) {
      
    }
  };
  /***********chatBox */

  const chatBox = () => {
    setOpenChatBox(true);

  }


  /*******related tabs  */
  const [value, setValue] = React.useState(1);
  const [background, setBackground] = useState('transparent')
  const changeTab = (event, newValue) => {
   
    if (value === 2) {
      setNbreAttachementsNonLus(0);
     
    }
   
    setValue(parseInt(newValue));
     if(newValue === 2 )
     {
      setBackground('white')
     }
     else {
      setBackground('transparent')
    }



  };
  

  
  
  const [attachmentList, setAttachmentList] = useState([])
  const [paginationModelCustom, setPaginationModelCustom] = useState({
    pageSize: 7,
    page: 1,
    nbPage: 0,
    idTicket: ticketDetails?.id
  })

  const fetchAttachments = async (paginationModelCustom) => {
    if (value === 2) {
      dispatch(getAttachementList(paginationModelCustom))
        .then(unwrapResult).then((res) => {
          setAttachmentList(res?.data?.data?.result)
          setPaginationModelCustom({
            pageSize: res?.data?.data?.pagination?.pageSize,
            page: res?.data?.data?.pagination?.currentPage,
            nbPage: res?.data?.data?.pagination?.nbPage,
            idTicket: ticketDetails?.id
          })
          dispatch(setPaginationModelSAVTA({
            page: res?.data?.pagination?.currentPage,
            pageSize: res?.data?.pagination?.pageSize,
            nbPage: res?.data?.pagination?.nbPage,
            idTicket: ticketDetails?.id
          }))
          Persistor.persist();
        }
        );
    }
  };

  const tabs = [
    {
      id: 1,
      label: 'Ticket',
      component: <TicketDetails
       ticket={ticketDetails && ticketDetails}
        formRef={formRef}
        setTicket={setTicket}
        setDirty={setDirty}
        onCheckRequiredFields={handleCheckRequiredFields} />,
      icon: <HomeIcon />,
      disabled: false,
    },
    {
      id: 2,
      label: 'Attachements',
      component: <AttachmentList idTicket={ticketDetails?.id}
        nbreAttachementsNonLus={nbreAttachementsNonLus}
        // formRef={formRef}
        fetchAttachments={fetchAttachments}

        setAttachmentList={setAttachmentList}
        attachmentList={attachmentList}

      />,
      counter: <AttachementCounter nbreAttachementsNonLus={nbreAttachementsNonLus} />,
      disabled: false,
    },
    // {
    //   id: 3,
    //   label: 'Contacts',
    //   component: <></>,
    //   disabled : true,
    //   // component: <ContactList idTicket={ticketDetails?.id} />
    // },
    // {
    //   id: 4,
    //   label: 'Actions',
    //   component: <></>,
    //   disabled : true,
    //   // component: <ActionList idTicket={ticketDetails?.id} />
    // },
    // {
    //   id: 5,
    //   label: 'Historique',
    //   component: <></>,
    //   disabled : true,
    // },
  ]

  const logoImage =
    ticketDetails?.pointDeVente?.logo &&
    ImageBaseUrl + `${ticketDetails.pointDeVente.logo}`;

  
  return (
    <>
      <div>
        {loading ? (

          <center>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />} />
          </center>
        ) : (
          <>
            {/* header card contains details of ticket and possible actions */}
            <Card elevation={0} sx={{marginRight : '10px'}} > 

              <GridContainer
                spacing={-1}
                display='flex'
                justifyContent='space-between'
                alignItems='center'
                direction='row'
              
              >

                <GridItem sx={{maxHeight : '100px'}} sm={3} md={1} xl={1} xxl={1}>
                  {/* <Avatar src={ImageBaseUrl + `${ticketDetails?.pointDeVente?.logo}`}  variant='square' alt={"logo"} sx={{width:'100%'}}>

              </Avatar> */}

                  {/* <img src={ticketDetails?.pointDeVente?.logo ? (ImageBaseUrl + `${ticketDetails?.pointDeVente?.logo}`) : placeholder} alt={"logo"} width={'100%'} />  */}
                  {/* {logoAvailable ? ( */}
                  <img src={logoImage} alt={"logo"} width={'100%'}/>
                  {/* ) : <div style={{
    margin : '5px'
  }}> <img src={placeholder} alt={"logo"} width={'100%'} /> </div>} */}

                </GridItem>


                <GridItem sx={9} sm={9} md={8} xl={8} xxl={8}>

                  <CardContent>

                    <H4 title={pageTitle} fontWeight='400'></H4>
                    <H4 title={ticketDetails?.pointDeVente?.nom} fontWeight='700'></H4>
                    <Tooltip title={ticketDetails?.sujet} placement='top'
                      sx={{
                        color: '#000',
                        fontFamily: "DM Sans",
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: 'normal',
                      }}
                    >
                      {ticketDetails?.sujet?.length > 60 ?
                        <span>{ticketDetails?.sujet?.charAt(0)?.toUpperCase() + ticketDetails?.sujet?.toLowerCase().slice(1, 60) + '...'}</span>
                        : <span>{ticketDetails?.sujet?.charAt(0)?.toUpperCase() + ticketDetails?.sujet?.toLowerCase().slice(1, 60)}</span>}

                      {/* <H5 title={ticketDetails?.sujet?.slice(0,70)}></H5> */}
                    </Tooltip>
                    <br></br>


                    {/* <H4 title={ticketDetails?.assigne?.nom} ></H4>  */}
                    <GetStatutChip statut={ticketDetails?.statut} />
                  </CardContent>
                </GridItem>

                <GridItem sx={12} sm={12} md={3} xl={3} xxl={3}>


                  {newTicket ? <Button
                    onClick={handleExecuteOpen}
                    startIcon={<ExecuteIcon color={Colors.velvet} />}
                    sx={{
                      color: `${Colors.velvet}`,
                      borderRadius: '16px',
                      boxShadow: '0.5px 1px 2px 0.5px rgba(16, 24, 40, 0.3)',
                      marginLeft: '230px'

                    }}>Exécuter</Button>
                    : null}

                  {canExecute ? <Button
                    onClick={handleExecute}
                    startIcon={<ExecuteIcon color={Colors.velvet} />}
                    sx={{
                      color: `${Colors.velvet}`,
                      borderRadius: '16px',
                      boxShadow: '0.5px 1px 2px 0.5px rgba(16, 24, 40, 0.3)',
                      marginLeft: '230px'


                    }}>Relancer</Button>
                    : null}

                  {enCours ? <> <Button
                    onClick={handleSuspend}
                    startIcon={<SuspendIcon color={Colors.velvet} />}
                    sx={{
                      color: `${Colors.strongOrange}`,
                      borderRadius: '16px',
                      boxShadow: '0.5px 1px 2px 0.5px rgba(16, 24, 40, 0.3)',
                      margin: '16px'

                    }}
                  > Mettre En Attente </Button>
                    <Button
                      onClick={handleResolve}
                      startIcon={<ResolvedIcon color={Colors.velvet} />}
                      sx={{
                        color: `${Colors.strongGreen}`,
                        borderRadius: '16px',
                        boxShadow: '0.5px 1px 2px 0.5px rgba(16, 24, 40, 0.3)',
                        margin: '16px'

                      }}>Résolu</Button> </>
                    : null}

                </GridItem>

              </GridContainer>

            </Card>
            {/* tabs conatins ticket details and related objects management components */}
            <div style={{
              backgroundColor: '#F4F7FE',
              height: '71vh',
              marginLeft: '0px',
              marginRight: '10px',

            //  borderRadius: '12px'
            }}>
              <GridContainer >

                <GridItem sx={11} sm={11} md={11} xl={10.95} xxl={10.95}>

                  <Tabs
                    value={value}
                    onChange={changeTab}
                    // variant="scrollable"
                    //  scrollButtons="auto"
                    // allowScrollButtonsMobile
                    sx={{
                      backgroundColor: `${Colors.white}`,
                      fontWeight: 'bold',
                      fontStyle: 'bold',
                      textTransform: 'none',
                      color: '#344054',
                      textAlign: 'center',
                      fontFamily: "DM Sans",
                      fontSize: '30px',
                      fontStyle: 'normal',
                      fontWeight: '500',
                      lineHeight: '145%',
                    }}
                  >
                    {tabs && tabs.map(tab => (
                      context.activeTab === tab.id ? (
                        tab.id === 1 ? (<>
                          <Tab
                            key={tab.id}
                            component={Box}
                            value={tab.id}
                            maxWidth='5px'
                            disabled={tab.disabled}
                            sx={{
                              textTransform: 'none',
                              justifyContent: 'center',
                              display: 'flex',
                              alignItems: 'center',
                              maxHeight: '3px',
                              height: '3px',
                              '&.MuiButtonBase-root': {
                                fontFamily: 'DM Sans',
                                marginleft: "5px",
                                marginRight: "5px",
                                marginBottom: "-5px",
                                marginTop: "8px",
                                width: '5px',
                                maxWidth: '5px',
                                height: '20px',
                                minHeight: '40px',
                                maxHeight: '20px',

                              }
                            }}
                            icon={tab.icon}
                            iconPosition="center"
                            iconwidth='5px'> </Tab>
                        </>) :
                          <Tab
                            key={tab.id}
                            component={Box}
                            value={tab.id}
                            disabled={tab.disabled}
                            label={
                              typeof tab.label === "string" ? (
                                <span style={{ marginLeft: 10 }}>
                                  {" "}
                                  {tab.label}
                                </span>
                              ) : (
                                null
                              )
                            }
                            sx={{
                              textTransform: 'none',
                              borderSpacing: '10px',
                              justifyContent: 'right',
                              display: 'flex',
                              alignItems: 'center',
                              maxHeight: '3px',
                              height: '3px',
                              '&.MuiButtonBase-root': {
                                marginleft: "5px",
                                marginRight: "5px",
                                marginBottom: "-5px",
                                marginTop: "8px",
                                height: '20px',
                                minHeight: '40px',
                                maxHeight: '20px',

                              }
                            }}
                            //icon={tab.counter}
                            iconPosition="end"
                            iconwidth='5px'

                          > </Tab>

                      ) : (

                        <Tab
                          key={tab.id}
                          component={Box}
                          value={tab.id}
                          disabled={tab.disabled}
                          label={
                            typeof tab.label === "string" ? (
                              <span style={{ display: 'flex', direction: 'row', marginLeft: 10 }}>
                                {" "}
                                {tab.label}
                                {tab.counter && React.cloneElement(tab.counter, {})}
                              </span>
                            ) : (
                              null
                            )
                          }

                          sx={{
                            backgroundColor: "white",
                            borderCollapse: 'separate',
                            // broderShadow: '0px -2px 2px 0px rgba(16, 24, 40, 0.08)',
                            // border: '1px solid #ededf0',
                            textTransform: 'none',
                            borderSpacing: '10px',
                            justifyContent: 'right',
                            display: 'flex',
                            alignItems: 'center',
                            maxHeight: '3px',
                            height: '3px',
                            '&.MuiButtonBase-root': {
                              //  padding: "5px, 5px, 5px, 0px",
                              //  broderShadow: '0px -2px 2px 0px rgba(16, 24, 40, 0.08)',
                              marginleft: "5px",
                              marginRight: "5px",
                              marginBottom: "-5px",
                              marginTop: "8px",
                              // borderTopLeftRadius: '16px',
                              // borderTopRightRadius: '16px',
                              // width : '100px',
                              height: '20px',
                              minHeight: '40px',
                              maxHeight: '20px',
                            }
                          }}
                          icon={tab.icon}
                          iconPosition="start"
                          iconwidth='20px'
                        />)
                    ))
                    }

                  </Tabs>
                  {tabs.map(tab =>
                    value === tab.id ? (<TabContainer key={tab.id}>
                      {tab.component}
                    </TabContainer>) : null
                  )}








                </GridItem>
           
                <GridItem sx={{ backgroundColor: 'white', 
                maxWidth: '30px', 
                maxHeight : '100px', marginLeft: '-10px', }}
                  sm={1} md={1} xl={0.98} xxl={0.98}>
                  <div >
                    <ChatComponent
                      open={openChatBox}
                      setOpen={setOpenChatBox}
                      fetchMessages={fetchMessages}
                      fetchAttachments={fetchAttachments}
                      paginationModelCustom={paginationModelCustom}
                      messagesList={messagesList}
                      setMessagesList={setMessagesList}
                      openTicket={ticketDetails?.id}
                      pageTitle={pageTitle}
                      nbreMessagesNonLus={nbreMessagesNonLus}
                      setNbreMessagesNonLus={setNbreMessagesNonLus}
                    //  joinRoomFunction={joinRoomFunction}
                    />

                  </div>
                  <div style={{ backgroundColor: '#F4F7FE', minHeight: '10px' }}> </div>
                 <div style={{backgroundColor: 'white', minHeight : '7px', minWidth :'153px'}}> </div>
                  {value === 2 ? <div style={{backgroundColor: 'white', minHeight : '55px', minWidth :'153px'}}> </div> : <></>}
                  <div style={{ backgroundColor: 'white', minHeight: '10px' }}> </div>
                  <div style={{ backgroundColor: background, 
                  minHeight: '529px', maxWidth : '100%',
                   borderRadius : '12px' , marginTop : '-2px'}}> 
                   </div>
                
                </GridItem>
                
              </GridContainer>
            </div>
            <ExecuteTicketInsideUpdateModal
              fetchMessages={fetchMessages}
              openModal={openModalExecute} setOpenModal={setOpenModalExecute}
              openTicket={ticketDetails?.id}
              status={ticketDetails?.statut}
              fetchData={fetchData} newTicket={newTicket}
            />

            <MettreEnAttenteTicketModal openModal={openModalSuspend} setOpenModal={setOpenModalSuspend} openTicket={ticketDetails?.id}
              fetchData={fetchData} fetchMessages={fetchMessages}
            />
            <ResolveTicketModal openModal={openModalResolve} setOpenModal={setOpenModalResolve} openTicket={ticketDetails?.id}
              fetchData={fetchData} fetchMessages={fetchMessages}
            />
          </>)}
      </div>
    </>
  );

}
