import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import GridContainer from '../../../GlobalComponents/Grid/GridContainer';
import GridItem from '../../../GlobalComponents/Grid/GridItem';

import { Button, Tooltip, CircularProgress,} from '@mui/material';

import { fetchFiltredTickets  } from '../../../ReduxTLK/features/SAV/TicketSlice';
import { useDispatch, useSelector } from 'react-redux';
import { array_status } from '../../../ReduxTLK/features/SAV/ticketList';
import moment from 'moment';

import { fetchUsersAgentSAV } from "../../../ReduxTLK/features/User/UserSlice";
import { unwrapResult } from '@reduxjs/toolkit';
import ExecuteIcon from '../../../GlobalComponents/IconComponents/ExcecuteIcon';
import ExecuteTicketModal from './ExecuteTicketModal';
import EditIcon from '../../../GlobalComponents/IconComponents/EditIcon';
import ViewIcon from '../../../GlobalComponents/IconComponents/ViewIcon';
import routes from "../../../routes";
import { NavContext } from '../../../App';
import H5 from '../../../GlobalComponents/TypoGraphy/H5';
import { Colors } from '../../../GlobalComponents/Utility/Colors';
import Avatar from '@mui/material/Avatar';
import EditSAVTicket from "../TicketDetails/Edit/EditSAVTicket";
import SAVIcon from "../../../GlobalComponents/IconComponents/SAVIcon";
import ViewSAVTicket from "../TicketDetails/View/ViewSAVTicket";
import { addTab, setLastActiveTab } from '../../../ReduxTLK/features/Tabs/TabsSlice';
import { Persistor } from '../../..';
import { setPaginationModelSAVTT } from '../../../ReduxTLK/features/Pagination/PaginationsSlice';
import { DataGrid,  gridClasses,  } from '@mui/x-data-grid';


import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import {  resetEditTicketDraftByIndex } from '../../../ReduxTLK/features/SAV/NewTicketDraftSlice';
import signalRService from '../../../GlobalComponents/Utility/SignalRConnection';
import StatusTicket from './StatusTicket';

import Filter from './Filter';
import { setFilterModel } from '../../../ReduxTLK/features/SAV/FilterSlice';
import CustomFooterComponent from './CustomFooterComponent';


export default function ListAllTickets() {
  const [openFiltreBox, setOpenFiltreBox] = useState(false);
  const [filterOptions, setFilterOptions] = useState({});
  const [dateBegin, setDateBegin] = useState()
  const [dateEnd, setDateEnd] = useState()
  const context = React.useContext(NavContext)
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(openFiltreBox ? 8 : 12);
  const [page, setPage] = useState(1);
  const paginationModelSAVTT = useSelector((state) => state.pagination.paginationModelSAVTT);

  const [paginationModelCustom, setPaginationModelCustom] = useState(openFiltreBox ? (paginationModelSAVTT.page ? paginationModelSAVTT : {

    pageSize:  8,
    page: 1,
    nbPage: 1
  }) : (paginationModelSAVTT.page ? paginationModelSAVTT : {

    pageSize: 12,
    page: 1,
    nbPage: 1
  }))
  const [checkboxSelection, setCheckboxSelection] = useState(true);
  const [disableRowSelectionOnClick, setDisableRowSelectionOnClick] = useState(true);
  const toReload = context.tabToBeReloaded

/*******************save filter form */
const savedFilterForm = useSelector((state) => state.filter.filter);
const fetchFiltredModel = useSelector((state) => state.filter.filterModel);
const filterBoxState = useSelector((state) => state.filter.filterBox);
useEffect(() => {
 
  setOpenFiltreBox(filterBoxState);
  dispatch(fetchFiltredTickets(fetchFiltredModel)).then(unwrapResult).then((res) => {
    
    dispatch(setFilterModel(
      {
        paginationModel : {
          page: res?.data?.pagination?.currentPage,
          pageSize: res?.data?.pagination?.pageSize,
          nbPage: res?.data?.pagination?.nbPage
        },
        body : {
          ...fetchFiltredModel.body
        }
        
  
      }
    ))
   
    
    setTicketList(res.data.result.tickets);
    setLoading(false);
    Persistor.persist();
  }
  );
  
}, []);
/***************************** */
  useEffect(() => {
    

    if (context.tabToBeReloaded?.toString() === 'allTickets') {

      
       
      dispatch(fetchFiltredTickets(fetchFiltredModel)).then(unwrapResult).then((res) => {
        dispatch(setFilterModel(
          {
            paginationModel : {
              page: res?.data?.pagination?.currentPage,
              pageSize: res?.data?.pagination?.pageSize,
              nbPage: res?.data?.pagination?.nbPage
            },
            body : {
              ...fetchFiltredModel.body
            }
            
      
          }
        ))

       
        
        setTicketList(res.data.result.tickets);
        setLoading(false);
        Persistor.persist();
      }
      );
    }
     // Cleanup function
  return () => {
    context.setTabToBeReloaded();
  };
  }, [context.tabToBeReloaded])


  useEffect(() => {
   
    dispatch(fetchUsersAgentSAV());



  }, [])
  const [ticketList, setTicketList] = useState([])
  const [selectedTicket, setSelectedTicket] = useState('')
  const fetchData = async (data) => {
   

    if ('dateCreation' in filterOptions) {
      delete filterOptions['dateCreation'];
    }
    dispatch(fetchFiltredTickets(data))
      .then(unwrapResult).then((res) => {
        dispatch(setFilterModel(
          {
            paginationModel : {
              page: res?.data?.pagination?.currentPage,
              pageSize: res?.data?.pagination?.pageSize,
              nbPage: res?.data?.pagination?.nbPage
            },
            body : {
              ...fetchFiltredModel.body
            }
            
      
          }
        ))
      
        setTicketList(res.data.result.tickets);
        setLoading(false);
        Persistor.persist();
      }
      );



  };

  const agentsSAV = useSelector((state) => state.user.agentsSAV)
  const getStatutChip = (foundStatut) => {

    if (!foundStatut) {
      return '';
    }
    return (
      <>
        <Button
          variant='outlined'
          disabled={true}
          sx={{
            //   marginTop: '10px', 
            //marginRight: "500px",
            textTransform: "none",
            borderRadius: 32,

            //  padding:'12px, 5px, 12px, 10px',
            '&.MuiButtonBase-root': {
              color: foundStatut.backgroundColor,
              borderColor: foundStatut.backgroundColor,
              borderRadius: '100px',
              width: '100px',
              height: '30px',
              border: '2px solid',
              fontWeight: 'bold'

            }
          }}
        //color="primary"
        > {foundStatut.nom}
        </Button>
        {/* <StyledChip label={foundStatut.nom} bgcolor={foundStatut.backgroundColor} /> */}
      </>
    );
  };
  const [openModal, setOpenModal] = useState(false)
  const handleExecute = (ticket) => {
    setOpenModal(true);
    setSelectedTicket(ticket);
  }

  const handleEdit = (ticket) => {

    // setSelectedTicket(ticket);
    // context.setSelectedTicket(ticket);
    openNewTab({ newTab, ticket });
  }
  const handleView = (ticket) => {

    // setSelectedTicket(ticket);
    // context.setSelectedTicket(ticket);
    openNewViewTab({ newTabView, ticket });
  }

 
  const newTab = routes && routes[0]?.pages[1]?.views[2]
  const newTabView = routes && routes[0]?.pages[1]?.views[4];
  function openNewTab(props) {
    setOpenFiltreBox(false)
    const title = '#' + props.ticket;
    const composedId = 'SAV_Ticket_' + props.ticket
    // Find any existing tab with the same ID
    const existingTabIndex = context.tabs.findIndex((tab) => tab.id === composedId);

    // If a tab with the ID already exists, activate it
    if (existingTabIndex !== -1) {
      context.setActiveTab(composedId);
      dispatch(setLastActiveTab(composedId));
      context.setRoomId(props.ticket)
      signalRService.onJoinRoom(props.ticket);
      return; // Exit the function to avoid creating a duplicate tab
    }

    // If no existing tab found, create a new one
    // setIndex(composedId);
    dispatch(resetEditTicketDraftByIndex(props.ticket));
    dispatch(addTab({
      module: 'SAV',
      action: 'Edit',
      identifier: props.ticket,
      id: composedId,
      label: title,
    }));

    context.setTabs([...context.tabs, {
      id: composedId,
      label: title,
      identifier: props.ticket,
      component: <EditSAVTicket id={props.ticket} fetchData={fetchData} paginationModelCustom={paginationModelCustom} />,
      closeable: true,
      icon: <SAVIcon />,
    }]);
    Persistor.persist();
    // context.setMaxTabIndex(index + 1);
    context.setActiveTab(composedId);
    dispatch(setLastActiveTab(composedId));
    context.setRoomId(props.ticket)
    signalRService.onJoinRoom(props.ticket);
    // context.setSelectedTicket(selectedTicket);
  }
  function openNewViewTab(props) {
    setOpenFiltreBox(false)
    const title = '#' + props.ticket;
    const composedId = 'SAV_Ticket_' + props.ticket
    // Find any existing tab with the same ID
    const existingTabIndex = context.tabs.findIndex((tab) => tab.id === composedId);

    // If a tab with the ID already exists, activate it
    if (existingTabIndex !== -1) {
      context.setActiveTab(composedId);
      dispatch(setLastActiveTab(composedId));
      context.setRoomId(props.ticket)
      signalRService.onJoinRoom(props.ticket);
      return; // Exit the function to avoid creating a duplicate tab
    }

    // If no existing tab found, create a new one
    // setIndex(composedId);
    dispatch(addTab({
      module: 'SAV',
      action: 'View',
      identifier: props.ticket,
      id: composedId,
      label: title,
      closeable: true,
    }));
    Persistor.persist();
    context.setTabs([...context.tabs, {
      id: composedId,
      label: title,
      identifier: props.ticket,
      component: <ViewSAVTicket id={props.ticket} />,
      closeable: true,
      icon: <SAVIcon />,
    }]);
    Persistor.persist();
    //context.setMaxTabIndex(index + 1);
    context.setActiveTab(composedId);

    dispatch(setLastActiveTab(composedId));
    context.setRoomId(props.ticket)
    signalRService.onJoinRoom(props.ticket);
    // context.setSelectedTicket(selectedTicket);
  }
 

  const handleRadioChange = (event) => {
   
    const selectedRowId = event.target.value;

  // Check if the row is already selected
  const isSelected = selectedRows.includes(selectedRowId);

  if (isSelected) {
    // Row is already selected, so remove it from the selectedRows array
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.filter((rowId) => rowId !== selectedRowId)
    );
  } else {
    // Row is not selected, so add it to the selectedRows array
    setSelectedRows((prevSelectedRows) => [...prevSelectedRows, selectedRowId]);
  }
  };
  const columns = [
  //   {
  //     field: 'select',
  //     headerName: '',
  //     flex: 0.01,
  //     align: 'center',
  //     renderCell: ((params) => {
  //       return (<>
  //       <Radio
  //       value={params.row.id}
  //       onChange={handleRadioChange}
  //    checkedIcon={<IconCheckboxChecked />}
  //   sx={{
  //   color: 'rgba(212,212,216,255)',
   
  //   '&.Mui-checked': {
  //     color: '#d4d4d8',
  //   },
  // }}/>
  //       </>)
  //     })
      
  //   },

    {
      field: 'id',
      headerName: 'Réference',
      // minWidth: 111 ,
      flex: 0.08,
      align: 'center',
      renderCell: ((params) => {
        return (<>

          <H5 title={params?.value} fontWeight='regular' />
        </>)
      })
    },
    {
      field: 'pointDeVente',
      headerName: 'Point de vente',
      // minWidth: 250,
      editable: true,
      flex: 0.18,

      renderCell: (params) => {
        // const agentSAV = agentsSAV.find(item => item.id === params.row.assigne);
        function capitalizeFirstLetter(text) {
          if (typeof text === 'string') { return text?.charAt(0)?.toUpperCase() + text?.slice(1)?.toLowerCase(); }
          else {
            return text
          }

        }

        const transformedChaine = capitalizeFirstLetter(params.row.chaine);
        const transformedPv = capitalizeFirstLetter(params.row.pointDeVente);
        return (
          <>
            <div>
              {/* {agentSAV ?  */}
              <GridContainer >
                <GridItem >
                  <Avatar sx={{ mt: 0, bgcolor: '#EDEDED', color: Colors.black, width: 40, height: 40 }}>{(params.row?.chaine)?.slice(0, 1)}</Avatar>
                </GridItem>
                <GridItem sx={{ ml: 2 }}>
                  <H5 title={transformedChaine} fontWeight='bold' />

                  <H5 title={transformedPv} color={Colors.primary} />
                </GridItem>
              </GridContainer>
            </div>
            {/* : ''} */}
          </>)

      }
    },
    {
      field: 'sujet',
      headerName: 'Sujet',
      // minWidth: 250,
      editable: true,
      flex: 0.25,

      renderCell: ((params) => {
        function capitalizeFirstLetter(text) {
          if (typeof text === 'string') { return text?.charAt(0)?.toUpperCase() + text?.slice(1)?.toLowerCase(); }
          else {
            return text
          }

        }

        const transformedSujet = capitalizeFirstLetter(params?.value);
        if (typeof transformedSujet === 'string' && transformedSujet?.length > 50) {
          const truncatedValue = transformedSujet?.slice(0, 50) + '...';


          return (<>
            <Tooltip title={transformedSujet} placement="top">
              <span>{truncatedValue}</span>
              {/* <H5 title={truncatedValue} /> */}
            </Tooltip>
          </>)
        }
        else {
          return (<>
            {/* <Tooltip title={transformedSujet} placement="top">
          <span>{transformedSujet}</span> */}
            <H5 title={transformedSujet} />
            {/* </Tooltip> */}
          </>)
        }
      })
    },
    {
      field: 'date',
      headerName: 'Date Ticket',
      //type: 'number',
      // minWidth: 150,
      editable: true,

      flex: 0.1,
      renderCell: ((params) => {
        const date_creation = moment(params?.value).format("DD-MM-YYYY");

        return (<>

          <H5 title={date_creation} />
        </>)
      })
    },
    {
      field: 'assigne',
      headerName: 'Assigné à ',
      // description: 'This column has a value getter and is not sortable.',
      sortable: false,
      // minWidth: 168,
      flex: 0.1,

      renderCell: (params) => {
        // const agentSAV = agentsSAV.find(item => item.id === params.row.assigne);

        return (<>
          {/* {agentSAV ?  */}
          <H5 title={params.row.assigne} />

          {/* : ''} */}
        </>)

      }
      // },
    },
    {
      field: 'statut',
      headerName: 'Statut',
      align: 'center',
      // minWidth: 170,
      editable: true,
      flex: 0.08,
      renderCell: (params) => {
        const foundStatut = array_status.find(statut => statut.id === params.row.statut);

        return <StatusTicket foundStatut={foundStatut} />;
      },
    },
    {
      field: 'action',
      align: 'center',
      headerName: '',
      editable: true,
      flex: 0.07,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const ticket = params.row;


        if (ticket.statut === 0) {
          //OUVERT
          return (
            <>
              <GridContainer
                // display='flex'
                justifyContent='center'
                alignItems='center'
                direction='row'

              >
                {/* <GridItem  sm={4} md={4} xl={4} xxl={4}> </GridItem> */}
                <GridItem sm={3} md={3} xl={3} xxl={3}>
                  <Button size='small' sx={{ position: 'center', width: 5, marginRight: 0, padding: 0, minWidth: '24px' }} onClick={() => handleView(ticket.id)}>

                    {/* <IconButton  size='small' sx={{  width : 5, margin : 0, padding: 0}}> */}
                    <ViewIcon></ViewIcon>
                    {/* </IconButton> */}
                  </Button>


                </GridItem>
                <GridItem sm={3} md={3} xl={3} xxl={3}>
                  <Button size='small' sx={{ position: 'center', width: 5, color: Colors.black, marginRight: 0, padding: 0, minWidth: '30px' }} onClick={() => handleExecute(ticket.id)}>

                    {/* <IconButton size='small' sx={{  width : 5, margin : 0, padding: 0}}> */}
                    <ExecuteIcon color={Colors.black}></ExecuteIcon>
                    {/* </IconButton> */}
                  </Button>
                </GridItem>
                {/* <GridItem sx={8} sm={8} md={8} xl={8} xxl={8}>
                 
                </GridItem>  */}
              </GridContainer>
            </>)
        }
        if (ticket.statut === 4 || ticket.statut === 1) {
          return (
            //non resolu et en attente
            <>
              <GridContainer
                //display='flex'
                justifyContent='center'
                alignItems='center'
                direction='row'
              >
              
                <GridItem sm={3} md={3} xl={3} xxl={3}>
                  <Button size='small' sx={{ position: 'center', width: 5, marginRight: 0, padding: 0, color: 'black', minWidth: '30px' }} onClick={() => handleView(ticket.id)}>

                    {/* <IconButton> */}
                    <ViewIcon></ViewIcon>
                    {/* </IconButton> */}
                  </Button>
                </GridItem>
                <GridItem sm={3} md={3} xl={3} xxl={3}>
                  <Button size='small' sx={{ position: 'center', width: 5, marginRight: 0, padding: 0, color: 'black', minWidth: '30px' }} onClick={() => handleEdit(params.row.id)}>

                    {/* <IconButton> */}
                    <EditIcon></EditIcon>
                    {/* </IconButton> */}
                  </Button>

                </GridItem>
              
              </GridContainer>
            </>)


        }
        if (ticket.statut === 6) {
          //cloture
          return (<>
            <GridContainer
              //display='flex'
              justifyContent='center'
              alignItems='center'
              direction='row'
            >
              <GridItem sx={4} sm={4} md={4} xl={4} xxl={4}>

              </GridItem>

              <GridItem sm={3} md={3} xl={3} xxl={3}>
                <Button size='small' sx={{ position: 'center', width: 5, marginRight: 0, padding: 0, color: 'black', minWidth: '30px' }} onClick={() => handleView(ticket.id)}>

                  {/* <IconButton> */}
                  <ViewIcon></ViewIcon>
                  {/* </IconButton> */}
                </Button>
              </GridItem>
              <GridItem sx={4} sm={4} md={4} xl={4} xxl={4}>

              </GridItem>
              {/* <GridItem sx={{ mr : 0}} sm={4} md={4} xl={4} xxl={4}>

</GridItem>   */}
            </GridContainer>
          </>)
        }
        else {
          //ENCOURS
          return (<>
            <GridContainer
              // display='flex'
              justifyContent='center'
              alignItems='center'
              direction='row'
            >
              {/* <GridItem sx={4} sm={4} md={4} xl={4} xxl={4}>

</GridItem>   */}
              <GridItem sx={3} sm={3} md={3} xl={3} xxl={3}>

                <Button size='small' sx={{ position: 'center', width: 5, marginRight: 0, padding: 0, color: 'black', minWidth: '30px' }} onClick={() => handleView(ticket.id)}>

                  {/* <IconButton> */}
                  <ViewIcon></ViewIcon>
                  {/* </IconButton> */}
                </Button>

              </GridItem>
              <GridItem sm={3} md={3} xl={3} xxl={3}>
                <Button size='small' sx={{ position: 'center', width: 5, marginRight: 0, padding: 0, color: 'black', minWidth: '30px' }} width="13px" onClick={() => handleEdit(ticket.id)}>

                  {/* <IconButton> */}
                  <EditIcon></EditIcon>
                  {/* </IconButton> */}
                </Button>
              </GridItem>
             
            </GridContainer>
          </>
          );
        }

      },

    },
  ];

  
  const [selectedRows, setSelectedRows] = React.useState([]);

  const handleSelectionChange = (selection) => {
    setSelectedRows(selection);
  };
  const [height, setHeight] = useState('79vh');
  useEffect(() => {
    if(openFiltreBox === true) 
    {
      setHeight('55vh')
      
    }
    else {
      setHeight('79vh')
    
    }
  
  },[openFiltreBox])
  return (
    <>
      <div>
        {loading ? (

          <center>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />} />
          </center>
        ) : (
          <>
             <Filter loading={loading} setLoading={setLoading} 
             fetchData={fetchData} setOpenFiltreBox={setOpenFiltreBox}
             setFilterOptions={setFilterOptions}
             dateBegin={dateBegin} dateEnd={dateEnd}
             setDateBegin={setDateBegin} setDateEnd={setDateEnd}
             openFiltreBox={openFiltreBox} setTicketList={setTicketList}
               setPaginationModelCustom={setPaginationModelCustom}
               paginationModelCustom={paginationModelCustom}
                setPaginationModelSAVTT={setPaginationModelSAVTT}
                /> 
            <div style={{ height: height, width: '99%', backgroundColor: 'white' }} >
                <DataGrid
                  scroll="paper"
                  rows={ticketList}
                  //hideFooter
                  columns={columns}
                  page={fetchFiltredModel?.paginationModel?.page}
                  setPage={setPage}
                  // fetchData={props.fetchData}
                  pageSize={fetchFiltredModel?.paginationModel?.pageSize}
                  //checkboxSelection
                  //  disableRowSelectionOnClick={props.disableRowSelectionOnClick}
                  //  disableColumnFilter
                  //checkboxSelection
                  disableSelectionOnClick
                  selectionModel={selectedRows}
                  onSelectionModelChange={(newSelection) => handleSelectionChange(newSelection.selectionModel)}

                  editMode={false}
                  disableColumnSelector={true}
                  
                  slots={{
                    pagination: false,
                    footer: CustomFooterComponent,
                    //Toolbar: GridToolbar,
                    
                  }}
                  slotProps={{
                     footer: { fetchFiltredModel, fetchData },
                    //checkbox: { className: classes.radioCheckbox },
                  }}
                  rowHeight={53}
                  columnHeaderHeight={42}
                  getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                  }
                  sx={{

                    [`& .${gridClasses.row}.even`]: {
                      backgroundColor: '#FCFCFC',
                      '&:hover, &.Mui-hovered': {
                        backgroundColor: '#FCFCFC',
                        '@media (hover: none)': {
                          backgroundColor: 'transparent',
                        },
                      },
                    },
                    [`& .${gridClasses.row}.odd`]: {
                      backgroundColor: Colors.white,
                      '&:hover, &.Mui-hovered': {
                        backgroundColor: Colors.white,
                        '@media (hover: none)': {
                          backgroundColor: 'transparent',
                        },
                      },
                    },
                    "& .MuiDataGrid-MuiButtonBase-root-MuiCheckbox-root.Mui-checked": {
                      /* Your custom styles go here */
                      /* For example, change background color */
                      backgroundColor: 'red'
              },
              "& .MuiDataGrid-columnHeaderTitleContainer": {
                height: '42x',
              margin: 0,
              padding: 0,
              //  color: var(--Grey-700, #344054);

              fontFamily: 'DM Sans',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '145%',
              letterSpacing: '-0.06px',
              
                  },
              "&.MuiDataGrid-columnHeaders MuiDataGrid-withBorderColor css-1iyq7zh-MuiDataGrid-columnHeaders" : {
                height: '0px',
              minHeight: '0px',
              maxHeight : '0px',
              alignItems: 'center',
            
                  },

              "&.MuiDataGrid-columnHeaderDraggableContainer" : {
                height: '0px',
              minHeight: '0px',
              maxHeight : '0px',
                  },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: '#FFFFFF',
              color: "#344054",
              color: "var(--grey-700, #344054)",
              fontFamily: 'DM Sans',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: '500',

              lineHeight: '17.4px',
              letterSpacing: '-0.05%',

              height: '42px'
                  },

              '& .MuiDataGrid-columnSeparator': {
                display: 'none',
                  },
              "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
                display: "none"
                  },
              ".MuiDataGrid-grid-scrollContainer": {
                overflow: "scroll !important",
              "&::-webkit-scrollbar-track": {
                width: "3px",
              backgroundColor: "#f5f5f5",
              borderRadius: "10px"
                    },
              "&::-webkit-scrollbar-thumb": {
                borderRadius: "10px",
              "WebkitBoxShadow": "inset 0 0 6px rgba(0,0,0,.3)",
              backgroundColor: "#f5f5f5"
                    }
                  }
                }}
              localeText={{
                noRowsLabel: loading ? (
                  <CircularProgress size={24} />
                ) : (
                  <Typography variant="body1">Aucun ticket</Typography>
                ),
              }}
            
            /> 



            </div>
            <ExecuteTicketModal openModal={openModal} setOpenModal={setOpenModal} openTicket={selectedTicket} fetchData={fetchData} paginationModelCustom={fetchFiltredModel} />
          </>)}
      </div>
    </>
  );
}
