import H4 from "../../../GlobalComponents/TypoGraphy/H4"

export default function NotesList({ notes }) {
    const userId = localStorage.getItem('userId')
   
    const blue = '#D6E2FF'
    const renderBackgroundColor = (sender) => {


        if (sender === userId) {
            return blue
        }
        else return 'white'
    }
    const formattedDate = (dateString) => {
        const options = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: false,
        };

        const formatted = new Date(dateString).toLocaleString('fr-FR', options);
        return formatted;
    };
    return (
        <>
           <div style={{ marginLeft : '25px'}}>
            {notes?.length > 0 ? <H4 title={'Notes internes :'}></H4> : <></>}
            <br></br>
            {notes?.map((note) => (
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                       


                    }}>
                        <span style={{
                            color: '#666687',
                            fontFamily: 'DM Sans',
                            fontSize: '14px',
                            fontWeight: 700,
                            lineHeight: '24px',
                            letterSpacing: '0em',
                            textAlign: 'right',
                        }}>{note?.agentSAV?.firstname} {''} {note?.agentSAV?.lastname}</span>
                    </div>
                    <div
                        style={{
                            border: '1px solid #D0D5DD',
                            maxWidth: '1040px',
                            borderRadius: '12px 12px 12px 0px',
                            backgroundColor: renderBackgroundColor(note?.agentSAV?.id),
                            fontFamily: 'DM Sans',
                            fontSize: '14px',
                            fontWeight: '400',
                            lineHeight: '24px',
                            letterSpacing: '0em',
                            textAlign: 'left',
                            wordWrap: 'break-word',

                        }}
                    >
                        {note?.contenu}
                    </div>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-end',
                        justifyContent: 'flex-end',
                        backgroundColor: 'white',
                        maxWidth: '1040px',
                    }}>
                        <span style={{
                            color: '#666687',
                            fontFamily: 'DM Sans',
                            fontSize: '14px',
                            fontWeight: 700,
                            lineHeight: '24px',
                            letterSpacing: '0em',
                            textAlign: 'right',
                        }}> {formattedDate(note?.dateCreation)}</span>


                    </div>
                </div>
            ))}
            </div>
        </>
    );
}