import { IconButton, Pagination } from "@mui/material";
import DataGridPaginationTitle from "../../../GlobalComponents/Grid/DataGridPaginationTitle";
import ChevronLeftIcon from "../../../GlobalComponents/IconComponents/ChevronLeftIcon";
import ChevronRightIcon from "../../../GlobalComponents/IconComponents/ChevronRightIcon";
import { Colors } from "../../../GlobalComponents/Utility/Colors";

export default function CustomFooterComponent({fetchFiltredModel, fetchData}) {
    const handlePageChangeNext = (event) => {

        const data = {
          paginationModel : {
            page: fetchFiltredModel?.paginationModel?.page + 1,
          pageSize: fetchFiltredModel?.paginationModel?.pageSize,
          nbPage: fetchFiltredModel?.paginationModel?.nbPage
          },
          body : {
            ...fetchFiltredModel.body
          }
          
    
        }
        
        fetchData(data);
      }
    
      const handlePageChangePrevious = (event) => {
       
        const data = {
          paginationModel : {
            page: fetchFiltredModel?.paginationModel?.page - 1,
          pageSize: fetchFiltredModel?.paginationModel?.pageSize,
          nbPage: fetchFiltredModel?.paginationModel?.nbPage
          },
          body : {
            ...fetchFiltredModel.body
          }
          
    
        }
        fetchData(data);
      }
      const handleChangePage = (event) => {
       
        const data = {
          paginationModel : {
          page: parseInt(event.target.textContent),
          pageSize: fetchFiltredModel?.paginationModel?.pageSize,
          nbPage: fetchFiltredModel?.paginationModel?.nbPage
          },
          body : {
            ...fetchFiltredModel.body
          }
          
    
        }
      
        fetchData(data);
      }
    return (
     <div style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '100%', // Adjust the maximum width as needed
       
      }}>
        {fetchFiltredModel?.paginationModel?.nbPage >= 1 ?
          <>
            <div style={{ minWidth: '70%', marginLeft :'50px' }}>
              <DataGridPaginationTitle count={fetchFiltredModel?.paginationModel?.nbPage} page={fetchFiltredModel?.paginationModel?.page} />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', minWidth: '25%', justifyContent: 'flex-end'  }}>
              
                <IconButton disabled={fetchFiltredModel?.paginationModel?.page === 1} onClick={() => handlePageChangePrevious()} sx={{color : Colors.primary}}>
                  <ChevronLeftIcon ></ChevronLeftIcon>
                </IconButton>
           
            
            


              <Pagination color="primary"
                shape='rounded' page={fetchFiltredModel?.paginationModel?.page} count={fetchFiltredModel?.paginationModel?.nbPage} sx={{
                  height: '38px',
                }}
                hidePrevButton
                hideNextButton
                onChange={handleChangePage}
              />
           

             

                <IconButton disabled={fetchFiltredModel?.paginationModel?.page === fetchFiltredModel?.paginationModel?.nbPage} onClick={() => handlePageChangeNext()} sx={{color : Colors.primary}}>
                  <ChevronRightIcon ></ChevronRightIcon>

                </IconButton>
                </div>

           
          </> : <></>}
          {
            fetchFiltredModel?.paginationModel?.nbPage === 0 ?  <>
            <div style={{ minWidth: '70%', marginLeft :'50px' }}>
                   <DataGridPaginationTitle count={fetchFiltredModel?.paginationModel?.nbPage} page={0} />
                
                   </div>
                   <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', minWidth: '25%', justifyContent: 'flex-end'  }}>
                     <IconButton  sx={{color : Colors.primary}}>
                       <ChevronLeftIcon ></ChevronLeftIcon>
                     </IconButton>
                     <div style={{shape : 'rounded', width : '25px',
                      height : '25px', backgroundColor : '#EEEEEE', 
                      color :'black',
                      borderRadius : '4px',
                      //marginBottom : '9px',
                       display :'flex', 
                      alignContent : 'center', alignItems : 'center'}}>
                      <span
                       style={{marginLeft : '10px', 
                       fontStyle:'DM Sans',
                        fontWeight : 500, fontSize : '12px'}}>0</span> 
                       </div>
                     <IconButton  sx={{color : Colors.primary}}>
                       <ChevronRightIcon ></ChevronRightIcon>
           
                     </IconButton>
                     </div>
                 </> : <></> }
                 </div>   
    
    );
  }